import React, { Component } from "react";

export default class TabBar extends Component {
	constructor(props) {
		super(props);

		this.tabClicked = this.tabClicked.bind(this);
		this.renderTab = this.renderTab.bind(this);
		if (!this.props.tabs) return;

		this.state = {
			activeTab: this.props.tabs[0].id,
		};
	}

	tabClicked(id) {
		this.setState({ activeTab: id });

		if (this.props.tabChanged) {
			this.props.tabChanged(id);
		}
	}

	renderTab(tab) {
		let tabClass = "nav-link";
		if (this.state.activeTab == tab.id) {
			tabClass += " active";
		}

		return (
			<li class="nav-item">
				<a class={tabClass} id={tab.id} onClick={() => this.tabClicked(tab.id)}>
					{tab.name}
				</a>
			</li>
		);
	}

	render() {
		let tabs = this.props.tabs;
		let tabRenders = [];

		for (let i = 0; i < tabs.length; i++) {
			const tab = tabs[i];
			tabRenders.push(this.renderTab(tab));
		}

		return (
			<ul class="nav nav-tabs" id="myTab" role="tablist">
				{tabRenders}
			</ul>
		);
	}
}
