import { Enviroment } from "../Enviroment/EnviromentVariables";
import { Locations } from "../Enviroment/Urls";

export default class SearchRepository {
	static async SearchJobs(searchTerm, page = 25, pageSize = 25) {
		if (searchTerm == undefined) {
			return [];
		}

		try {
			var url = Enviroment.JOB_API_LOCATION + Locations.Universal_Search.url;
			var results = await fetch(url +
				"?page=" + page
				+ "&searchQuery=" + searchTerm
				+ "&pageSize=" + pageSize);

			if (!results.ok) {
				return false;
			}

			return await results.json();
		} catch (error) {
			console.error(error);
			return false;
		}
	}
}
