export default class Job {
    constructor(name, description, notes, start, end, customer, site) {
        this.name = name;
        this.description = description;
        this.notes = notes;
        this.startDate = start;
        this.endDate = end;
        this.linkedCustomer = customer;
        this.linkedSite = site;
    }

    addParts(parts) {
        
    }
}