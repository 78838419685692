import React from "react";

/**
 * The default sitesolve button components
 */
export default class PaginationButton extends React.Component {
	constructor(props) {
		super(props);
		this.state = props;
	}

	componentWillReceiveProps(nextProps) {
		this.setState(nextProps);
	}

	render() {
		var classString = "btn btn-dark";

		if (this.state.isActive) {
			classString += " active";
		}

		if (this.state.disabled === true) {
			classString += " disabled";
		}

		let centerText = this.state.number;
		if (this.state.children) {
			centerText = this.state.children;
		}

		return (
			<button className={classString} onClick={() => this.state.onClick(this.state.number)}>
				{centerText}
			</button>
		);
	}
}
