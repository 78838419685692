import React from "react";
import { Provider } from "react-redux";
import { createStore, combineReducers } from "redux";
import notificationReducer from "./notificationReducer";
import layoutReducer from "./layoutReducer";
import wizardReducer from "./wizardReducer";
import kanbanReducer from "./kanbanReducer";

const rootReducer = combineReducers({
	notificationReducer: notificationReducer,
	layoutReducer: layoutReducer,
	wizardReducer,
	kanbanReducer,
});

export default rootReducer;
