import React, { Component } from "react";
import PaginationSection from "./PaginationSection";
import SwitchButton from "../Buttons/SwitchButton";

/**
 * Required props: loadFunction the async loading function
 * Renderer: the rendering function
 */
export default class PaginatedListComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			displayMode: "list",
			searchTerm: "",
			sortOrder: "",
			hasDisplayToggle: true,
			currentPage: 1,
			currentData: {
				totalPages: 1,
				listData: [],
			},
		};

		if (this.props.displayMode) {
			this.state.displayMode = this.props.displayMode;
		}

		if (this.props.hasDisplayToggle !== undefined) {
			this.state.hasDisplayToggle = this.props.hasDisplayToggle;
		}

		this.searchTermChanged = this.searchTermChanged.bind(this);
		this.pageChanged = this.pageChanged.bind(this);
		this.loadItems = this.loadItems.bind(this);
		this.getContent = this.getContent.bind(this);
		this.listRenderer = this.listRenderer.bind(this);
		this.gridRenderer = this.gridRenderer.bind(this);
		this.displayModeToggle = this.displayModeToggle.bind(this);
	}

	async componentWillMount() {
		this.loadItems(this.state.currentPage, this.state.sortOrder);
	}

	/**
	 * Makes a search query
	 * @param {*} searchTerm
	 */
	searchTermChanged(searchTerm) {
		if (searchTerm == undefined || searchTerm == "") {
			this.loadItems(this.state.currentPage, this.state.searchTerm, this.state.sortOrder);
			this.setState({ searchTerm });
			return;
		}
		this.searchItems(this.state.currentPage, searchTerm, this.state.sortOrder).finally(this.setState({ searchTerm }));
	}

	/**
	 * Changes the current page
	 * @param {int} currentPage
	 */
	pageChanged(currentPage) {
		this.loadItems(currentPage, this.state.sortOrder).finally(this.setState({ currentPage }));
	}

	/**
	 * Changes the sort order of a object, requests new data on change
	 * @param {string} sortOrder
	 */
	sortOrderChanged(sortOrder) {
		this.loadItems(this.state.currentPage, sortOrder).finally(this.setState({ sortOrder }));
	}

	/**
	 * Loads the components from the supplied function
	 * @param {int} currentPage
	 * @param {string} searchTerm
	 * @param {string} sortOrder
	 */
	async loadItems(currentPage, sortOrder) {
		try {
			var loadedItems = await this.props.loadFunction(currentPage, sortOrder);

			if (loadedItems === false) {
				console.warn("Couldn't load the items");
				return;
			}

			this.setState({
				currentData: loadedItems,
				currentPage: loadedItems.pageIndex,
			});
		} catch (error) {
			console.error(error);
		}
	}


	/**
	 * Loads the components from the supplied function
	 * @param {int} currentPage
	 * @param {string} searchTerm
	 * @param {string} sortOrder
	 */
	async searchItems(currentPage, searchTerm, sortOrder) {
		try {
			var loadedItems = await this.props.searchFunction(currentPage, searchTerm, sortOrder);

			if (loadedItems === false) {
				console.warn("Couldn't load the items");
				return;
			}

			this.setState({
				currentData: loadedItems,
				currentPage: loadedItems.pageIndex,
			});
		} catch (error) {
			console.error(error);
		}
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.searchTerm !== undefined) {
			this.setState({ searchTerm: nextProps.searchTerm });
			this.searchTermChanged(nextProps.searchTerm);
		}
		if (nextProps.displayMode) {
			this.setState({ displayMode: nextProps.displayMode });
		}
	}
	/**
	 * Fetches the content with overloads for no list
	 * @param {string} mode
	 */
	getContent(mode) {
		if (this.state.currentData.listData === undefined || this.state.currentData.listData.length == 0) {
			return <div>We couldn't find any items to render :( </div>;
		}

		if (this.props.renderer) {
			var elements = [];
			this.state.currentData.listData.forEach(element => {
				elements.push(this.props.renderer(mode, element));
			});
			return elements;
		}

		return <div>We couldn't find the renderer :( </div>;
	}

	/**
	 * Renders the content in a table
	 */
	listRenderer() {
		var headers = [];
		this.props.tableHeaders.forEach(header => {
			headers.push(<th>{header}</th>);
		});
		let content = this.getContent("list");
		return (
			<div className="table-responsive">
				<table className="table align-td-middle table-card">
					<thead>
						<tr>{headers}</tr>
					</thead>
					<tbody>{content}</tbody>
				</table>
			</div>
		);
	}

	/**
	 * Renders the content in a grid mode
	 */
	gridRenderer() {
		return this.getContent("grid");
	}

	/**
	 * Changed the display mode based on a callback
	 * @param {string} mode
	 */
	displayModeToggle(mode) {
		this.setState({ displayMode: mode });
	}

	GetPaginationContent() {
		var paginationContent = <></>;
		if (this.state.currentData.totalPages > 1) {
			paginationContent = <PaginationSection currentPage={this.state.currentPage} selectionFunction={this.pageChanged} totalPages={this.state.currentData.totalPages} />;
		}
		return paginationContent;
	}

	GetDisplayToggle(activeButton) {
		let displayToggle = <></>;
		if (this.state.hasDisplayToggle === true) {
			displayToggle = (
				<SwitchButton
					onClick={this.displayModeToggle}
					activeButton={activeButton}
					aButton={{
						name: <i className="mdi mdi-view-headline" />,
						value: "list",
					}}
					bButton={{ name: <i className="mdi mdi-view-grid" />, value: "grid" }}
				/>
			);
		}
		return displayToggle;
	}

	render() {
		let listContent = undefined;
		let activeButton = 0;
		if (this.state.displayMode === "list") {
			listContent = this.listRenderer();
			activeButton = 0;
		} else {
			listContent = this.gridRenderer();
			activeButton = 1;
		}

		var paginationContent = this.GetPaginationContent();

		let displayToggle = this.GetDisplayToggle(activeButton);

		return (
			<div className="paginatedList">
				<div className="listHeader">{displayToggle}</div>
				<div className="listBody">{listContent}</div>

				<div className="listFooter">{paginationContent}</div>
			</div>
		);
	}
}
