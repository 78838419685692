import React, { Component } from "react";
import SidebarHeader from "./sidebarHeader";
import SidebarButton from "./sidebarButton";
import { connect } from "react-redux";

class Sidebar extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		var sidebarExtraClass = "";
		if (this.state.sidebarShown === true) {
			sidebarExtraClass = "sidebar-show";
		}

		return (
			<aside className={"admin-sidebar " + sidebarExtraClass}>
				<SidebarHeader title="Easy Contractor"></SidebarHeader>
				<div className="admin-sidebar-wrapper js-scrollbar">
					<ul className="menu">
						<SidebarButton buttonName="Dashboard" href="/"></SidebarButton>
						<SidebarButton buttonName="Calendar"></SidebarButton>
						<SidebarButton buttonName="Jobs" href="/jobs"></SidebarButton>
						<SidebarButton buttonName="Quotes"></SidebarButton>
						<SidebarButton buttonName="My Parts" href="/parts"></SidebarButton>
						<SidebarButton buttonName="Teams"></SidebarButton>
						<SidebarButton buttonName="Analytics"></SidebarButton>
					</ul>
				</div>
			</aside>
		);
	}
}

const mapStateToProps = function(state) {
	return state;
};

export default connect(mapStateToProps)(Sidebar);
