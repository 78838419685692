import React, { Component } from "react";
import { connect } from "react-redux";
import actions from "../../actions/actions";
import SearchBox from "../../components/SearchBox/SearchBox";
import PaginatedListComponent from "../../components/Pagination/PaginatedListComponent";
import SwitchButton from "../../components/Buttons/SwitchButton";
import PaginationSection from "../../components/Pagination/PaginationSection";
import Button from "../../components/Buttons/Button";
import JobRepository from "../../repositories/JobRepository";
import JobCell from "../../components/Jobs/JobCell";
import JobRow from "../../components/Jobs/JobRow";

class JobIndex extends Component {
	constructor(props) {
		super(props);
		this.displayNotification = this.displayNotification.bind(this);
		this.state = {
			currentPage: 1,
			searchTerm: "",
			parts: [],
			totalPages: 0,
			activeButton: 1,
			renderMode: "grid",
		};
		this.search = this.search.bind(this);
		this.toggleRenderMode = this.toggleRenderMode.bind(this);
	}

	displayNotification() {
		this.props.dispatch(actions.addNotificationObject({ title: "Successfully created the job", description: "I've successfully created a job", status: 0, icon: "check-circle" }));
	}

	search(searchTerm) {
		this.setState({ searchTerm });
	}

	toggleRenderMode(mode) {
		let activeButton = 0;
		if (mode === "grid") {
			activeButton = 1;
		}
		this.setState({ renderMode: mode, activeButton });
	}

	renderParts(mode, job) {
		if (mode == "grid") {
			return <JobCell key={job.objectId} job={job} />;
		} else {
			return <JobRow key={job.objectId} job={job} />;
		}
	}

	render() {
		return (
			<div>
				<div className="bg-dark">
					<div className="row p-t-20 p-b-10">
						<div className="col-md-6 text-center mx-auto text-white">
							<div className="form-dark headerSearchBar">
								<SearchBox responseFunction={this.search} placeHolder="Search Job Catalog"></SearchBox>
								<SwitchButton
									onClick={this.toggleRenderMode}
									activeButton={this.state.activeButton}
									aButton={{
										name: <i className="mdi mdi-view-headline" />,
										value: "list",
									}}
									bButton={{
										name: <i className="mdi mdi-view-grid" />,
										value: "grid",
									}}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-2">
						<div className="row">
							<div className="card m-b-30 partFilter">
								<div className="card-header">
									<h5 className="m-b-0">Filter</h5>
									<p className="m-b-0 text-muted">Filter Jobs</p>
								</div>
								<div className="card-body">
									<div className="m-b-10">
										<label> Slider</label>
									</div>
								</div>
							</div>
						</div>

						<div className="row">
							<div className="col-12">
								<Button className="btn-primary" href="/wizard">Create new job</Button>
								<Button className="btn-primary" onClick={this.displayNotification}>Notify</Button>
							</div>
						</div>
					</div>
					<div className="col-10">
						<PaginatedListComponent
							searchTerm={this.state.searchTerm}
							displayMode="grid"
							tableHeaders={["Name", "State", "Creation Date", ""]}
							renderer={this.renderParts}
							loadFunction={JobRepository.GetJobPage}
							searchFunction={JobRepository.SearchJobs}
							hasDisplayToggle={false}
							displayMode={this.state.renderMode}
						/>
					</div>
				</div>
			</div>
		);
	}
}

export default connect()(JobIndex);
