import React, { Component } from "react";
import { IconButton, Tooltip, Avatar } from "@material-ui/core";
import { Event, Dashboard, List, Apps, ViewColumn } from "@material-ui/icons";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import TaskEditSideBar from "../../components/KanBan/TaskEditSideBar";
import Board from "../../components/KanBan/Board";
import TaskCalender from "../../components/Calender/TaskCalender";
import { withRouter } from "react-router";
import QueryString from "query-string";

class JobTasks extends Component {
	constructor(props) {
		super(props);
		this.state = { sideBarShowing: false, renderState: "board" };
		this.renderModeChanged = this.renderModeChanged.bind(this);
		this.getRenderState = this.getRenderState.bind(this);
		var existingQuerys = QueryString.parse(this.props.location.search);
		if (existingQuerys.mode) {
			this.state.renderState = existingQuerys.mode;
		}
	}

	renderModeChanged(e, mode) {
		console.log(mode);
		console.log(e);
		this.props.history.push({
			search: "?" + new URLSearchParams({ mode: mode }).toString(),
		});
		this.setState({ renderState: mode });
	}

	getRenderState() {
		switch (this.state.renderState) {
			case "board":
				return <Board jobId={this.props.match.params.jobId} />;
			case "calender":
				return <TaskCalender jobId={this.props.match.params.jobId}></TaskCalender>;
		}
	}

	render() {
		let renderState = this.state.renderState;
		let content = this.getRenderState();
		return (
			<>
				<TaskEditSideBar showing={this.state.sideBarShowing}></TaskEditSideBar>
				<div className="container-fluid ">
					<div className="row p-t-20">
						<div className="col-md-6">
							<h5
								className="d-inline-block m-r-15"
								href="#"
								onClick={() => {
									this.props.history.push("/job/" + this.props.match.params.jobId + "/details");
								}}
							>
								<i className="mdi mdi-view-dashboard" /> Test Job Name - Tasks
							</h5>

							<ToggleButtonGroup value={renderState} size="small" onChange={this.renderModeChanged} exclusive aria-label="render options">
								<ToggleButton value="board" aria-label="board">
									<ViewColumn />
								</ToggleButton>
								<ToggleButton value="calender" aria-label="calender">
									<Event />
								</ToggleButton>
								<ToggleButton value="list" aria-label="list">
									<List />
								</ToggleButton>
								<ToggleButton value="grid" aria-label="grid">
									<Apps />
								</ToggleButton>
							</ToggleButtonGroup>
						</div>
						<div className="col-md-6 text-right d-none d-lg-block">
							<span className="text-muted m-r-10">Customer</span>
							<Tooltip title="Edwards Contractors" className="m-r-10">
								<Avatar className="av-xs">EC</Avatar>
							</Tooltip>
							<div className="text-muted d-inline-block  ">
								<i className="mdi mdi-lock"></i> Private Job
							</div>
						</div>
					</div>
					<div className="tasksContnet">{content}</div>
				</div>
			</>
		);
	}
}

export default withRouter(JobTasks);
