import React, { Component } from "react";
import { withRouter } from "react-router-dom";

 class Button extends Component {
	constructor(props) {
		super(props);

		this.state = props;
	}

	componentWillReceiveProps(nextProps) {
		this.setState(nextProps);
	}

	render() {
		var buttonClass = this.state.className;
		var loadingBody = <></>;
		if (this.state.loading === true) {
			loadingBody = <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>;
		}

		if (this.state.disabled === true || this.state.loading === true) {
			buttonClass += " disabled";
		}

		if (this.props.href) {
			return (
				<button type="button" class={"btn " + buttonClass} onClick={() => this.props.history.push(this.props.href)}>
					{loadingBody}
					{this.state.children}
				</button>
			);
		}
		return (
			<button type="button" class={"btn " + buttonClass} onClick={this.state.onClick}>
				{loadingBody}
				{this.state.children}
			</button>
		);
	}
}

export default withRouter(Button);