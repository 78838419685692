import { Enviroment } from "../Enviroment/EnviromentVariables";
import { Locations } from "../Enviroment/Urls";

export default class FileRepository {
	constructor() {}

	static async GetFile(fileId) {
		try {
			var url = Enviroment.FILE_URL_LOCATION + Locations.File_Details.url + "/" + fileId;
			var fileData = await fetch(url);

			if (!fileData.ok) {
				return false;
			}

			return await fileData.blob();
		} catch (error) {
			console.error(error);
			return false;
		}
    }
    
    static async GetThumbnailFromStorage(fileLocation) {     
        if(!fileLocation)
        return;

        var index = fileLocation.lastIndexOf('.');
        var newUrl = fileLocation.slice(0, index) + "" + fileLocation.slice(index, fileLocation.length);
        return this.GetFileFromStorage(newUrl);
    }

    static async GetFileFromStorage(fileLocation) {
		try {
			var fileData = await fetch(fileLocation, {mode:"no-cors"});

			if (!fileData.ok) {
				return false;
			}

			return await fileData.blob();
		} catch (error) {
			console.error(error);
			return false;
		}
	}

	static async UploadFile(fileData) {
		try {
			var url = Enviroment.FILE_URL_LOCATION + Locations.File_Uploading.url + "/";

			let method = Locations.File_Uploading.method;
			let fileFormData = new FormData();
			fileFormData.append(fileData.name, fileData);
			var fileData = await fetch(url, {
				method,
                body: fileFormData,
                
			});

			if (!fileData.ok) {
				return false;
			}

            var fileJson =  await fileData.json();
            return fileJson;
		} catch (error) {
			console.error(error);
			return false;
		}
	}
}
