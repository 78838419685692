import React, { Component } from "react";
import TaskRepository from "../../repositories/TaskRepository";
import { withRouter } from "react-router-dom";
import KanBanTask from "./KanBanTask";
import { Droppable, Draggable } from "react-beautiful-dnd";
import KanBanTaskCreation from "./KanBanTaskCreation";
import Task from "../../models/Task";

class Group extends Component {
	constructor(props) {
		super(props);
		this.state = { tasks: this.props.tasks, addItemShown: false };
		this.addItem = this.addItem.bind(this);
		this.addClose = this.addClose.bind(this);
		this.addSubmit = this.addSubmit.bind(this);
	}

	renderTasks() {
		let taskRenders = [];
		let tasks = this.state.tasks.listData;
		for (let i = 0; i < tasks.length; i++) {
			const element = tasks[i];
			taskRenders.push(
				<Draggable key={element.objectId} draggableId={element.objectId} index={i}>
					{(provided, snapshot) => <KanBanTask task={element} index={i} innerRef={provided.innerRef} provided={provided} />}
				</Draggable>
			);
		}
		return taskRenders;
	}

	addSubmit(title) {
		var task = new Task(title, "", this.props.status, new Date(), new Date(), this.props.match.params.jobId);
		TaskRepository.CreateTask(task).then(result => {
			console.log(result);
			if (result !== false) {
                let tasks = this.state.tasks;
                tasks.listData.push(result);
                this.setState({tasks: tasks});
				this.addClose();
			}
		});
	}

	addItem() {
		this.setState({ addItemShown: true });
	}

	addClose() {
		this.setState({ addItemShown: false });
	}

	render() {
		let tasks = this.renderTasks();
		let addItem = <></>;
		if (this.state.addItemShown === true) {
			addItem = <KanBanTaskCreation onSubmit={this.addSubmit} onClose={this.addClose}></KanBanTaskCreation>;
		}

		return (
			<div className="kanban-list col-md-auto col ui-sortable-handle" ref={this.props.innerRef}>
				<div className="kanban-list-container">
					<div className="row ">
						<div className="col-md-9">
							<div contentEditable="" className="text-truncate w-100 kanban-board-title h5">
								{this.props.status}
							</div>
						</div>
						<div className="col text-right">
							<div className="dropdown">
								<a href="#" className=" mdi mdi-18px mdi-dots-horizontal" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></a>
								<div className="dropdown-menu dropdown-menu-right">
									<a className="dropdown-item js-copylink" href="#">
										<i className="mdi mdi-link"></i> Copy Link
									</a>
									<a className="dropdown-item js-delete-list" href="#">
										<i className="mdi mdi-delete"></i>
										Delete
									</a>
								</div>
							</div>
						</div>
					</div>
					<div className="kanban-list-wrapper ui-sortable">
						{tasks}
						{addItem}
					</div>
					<div className="m-b-10  ">
						<a href="#!" onClick={this.addItem} className="js-addCard opacity-75  ">
							<i className="mdi mdi-plus"></i> Add Item
						</a>
					</div>
				</div>
			</div>
		);
	}
}

export default withRouter(Group);
