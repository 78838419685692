import React, { Component } from "react";

export default class TextInput extends Component {
  constructor(props) {
    super(props);
    this.type = "text";
    if (this.props.type) {
      this.type = this.props.type;
    }
  }

  render() {
    let helpElement = <></>;
    let inputElement = <></>;

    if (this.props.helpText) {
      helpElement = (
        <small className="form-text text-muted">{this.props.helpText}</small>
      );
    }

    if (this.props.type !== "textarea") {
      inputElement = (
        <input
          type={this.type}
          className="form-control"
          placeholder={this.props.placeholder}
          onChange={(e) => this.props.onChange(e.nativeEvent)}
          value={this.props.children}
        />
      );
    } else {
      inputElement = (
        <textarea
          className="form-control"
          placeholder={this.props.placeholder}
          onChange={(e) => this.props.onChange(e.nativeEvent)}
          value={this.props.children}
        />
      );
    }

    return (
      <div className="form-group">
        <label>{this.props.title}</label>
        {inputElement}
        {helpElement}
      </div>
    );
  }
}
