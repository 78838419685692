import React, { Component } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import TaskEventCreation from "./TaskEventCreation";
import TaskRepository from "../../repositories/TaskRepository";
import EventObject from "../../models/EventObject";
import actions from "../../actions/actions";
import { connect } from "react-redux";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
class TaskCalender extends Component {
	constructor(props) {
		super(props);
		this.loadEvents = this.loadEvents.bind(this);
		this.eventClicked = this.eventClicked.bind(this);
	}

	loadEvents(eventInfo, success, failure) {
		TaskRepository.GetTaskByDate(this.props.jobId, eventInfo.start, eventInfo.end)
			.then(response => {
				if (response === false) {
					failure("Unknown Error");
				}
				var responseEventObjects = [];
				response.forEach(element => {
					responseEventObjects.push(new EventObject(element));
				});
				success(responseEventObjects);
			})
			.catch(error => failure(error));
	}

	eventClicked(event) {
		console.log(event);
		this.props.dispatch(actions.taskEdited(event.event.extendedProps.task));
	}

	render() {
		return (
			<div className="container calender p-t-50">
				<div className="row">
					<div className="col-lg-3 m-b-50">
						<TaskEventCreation />
					</div>
					<div className="col-lg-9 m-b-50">
						<div className="card">
							<div className="card-body">
								<FullCalendar
									defaultView="dayGridMonth"
									header={{
										left: "prev,next",
										center: "title",
										right: "dayGridMonth,timeGridWeek,dayGridDay",
									}}
									eventClick={this.eventClicked}
									editable={true}
									aspectRatio={1.9}
									events={this.loadEvents}
									plugins={[dayGridPlugin, timeGridPlugin, listPlugin]}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default connect()(TaskCalender);
