import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PartList from "../../components/Parts/PartList";
import actions from "../../actions/actions";
import JobRepository from "../../repositories/JobRepository";
import ImageUploader from "../../components/Images/ImageUploader";

import Chart from "react-apexcharts";
import UserRow from "../../components/User/UserRow";

import MUIRichTextEditor from 'mui-rte'
import { createMuiTheme, Theme, MuiThemeProvider } from '@material-ui/core/styles'
import { stateToHTML } from 'draft-js-export-html';
import { convertFromHTML, ContentState, convertToRaw } from 'draft-js'


import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'

class JobDetail extends Component {
	constructor(props) {
		super(props);
		this.state = { job: {}, jobId: this.props.match.params.jobId };
		this.GetJob = this.GetJob.bind(this);
		this.submitFunction = this.submitFunction.bind(this);
	}

	async componentWillMount() {
		let jobId = this.props.match.params.jobId;
		await this.GetJob(jobId);
	}

	async GetJob(jobId) {
		var job = await JobRepository.GetJobDetails(jobId);
		if (job === false) {
			return;
		}
		console.log(job);
		let jobDescription = job.description ? job.description : "<p></p>"
		let descriptionHtml = convertFromHTML(jobDescription);
		let stateBlocks = ContentState.createFromBlockArray(descriptionHtml.contentBlocks, descriptionHtml.entityMap)
		let content = JSON.stringify(convertToRaw(stateBlocks))
		this.setState({ job, jobDescription: content });
	}

	submitFunction(image) { }

	render() {
		var options = {
			// colors:colors[18],
			chart: {
				height: 350,
				type: "radialBar",
			},
			plotOptions: {
				radialBar: {
					startAngle: -90,
					endAngle: 90,
					dataLabels: {
						name: {
							show: false,
						},
						value: {
							offsetY: 0,
							fontSize: "22px",
						},
					},
				},
			},
			fill: {
				type: "gradient",
				gradient: {
					shade: "dark",
					shadeIntensity: 0.15,
					inverseColors: false,
					opacityFrom: 1,
					opacityTo: 1,
					stops: [0, 50, 65, 91],
				},
			},
			stroke: {
				dashArray: 4,
			},
			series: [67],
		};
		let jobEntity = this.state.job;

		return (
			<>
				<div class="bg-dark bg-dots m-b-20">
					<div class="container">
						<div class="row p-b-20 p-t-40">
							<div class="col-md-6 text-white p-b-30">
								<div class="media">
									<div class="media-body">
										<h1>{jobEntity.name} </h1>
										<p class="opacity-75"></p>
									</div>
								</div>
							</div>
							<div class="col-md-5 text-center m-b-30 ml-auto">
								<div class="rounded text-white bg-white-translucent">
									<div class="p-all-15">
										<div class="row">
											<div class="col-md-6 my-2 m-md-0">
												<div class="text-overline opacity-75">Total</div>
												<h3 class="m-0 text-success">£10,300</h3>
											</div>
											<div class="col-md-6 my-2 m-md-0">
												<div class="text-overline opacity-75">Pending Amount</div>
												<h3 class="m-0 text-danger">£1,230</h3>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>{" "}
				<div className="jobPage m-l-30 m-r-30">
					<div className="row">
						<div class="col-md-6 col-lg-6">
							<div class="card m-b-30 bg-dark text-white">
								<div class="card-header">
									<h5 class="m-b-0">Summary</h5>
									<p class="m-b-0 opacity-50"></p>
								</div>
								<div class="card-body ">
									<form class="form-dark" action="#">
										<div class="form-row">
											<div class="form-group  col-md-12">
												<label>Title</label>
												<input type="text" value={jobEntity.name} class="form-control" placeholder="Title" />
											</div>
										</div>
										<div class="form-group jobDetailsEditor">
											<label>Description</label>
											<MuiThemeProvider theme={theme}>
												<MUIRichTextEditor
													variant="outlined"
													label="Description"
													name="Description"
													defaultValue={this.state.jobDescription}
													controls={["title", "bold", "italic", "underline", "strikethrough", "undo", "redo", "link", "quote", "bulletList", "numberList", "clear"]}
													onChange={this.onDescriptionUpdated} />
											</MuiThemeProvider>
										</div>
										<div class="form-group">
											<label>Notes</label>
											<textarea type="text" class="form-control" value={jobEntity.notes} placeholder="Notes" />
										</div>
										<div class="form-row">
											<div class="form-group col-md-4">
												<label>State</label>
												<select class="form-control">
													<option selected="">Choose...</option>
													<option>Open</option>
													<option>In-Progress</option>
													<option>Done</option>
												</select>
											</div>
											<div class=" col-md-4" />
											<div class="form-group col-md-4">
												<label>Type</label>
												<select class="form-control">
													<option selected="">Choose...</option>
													<option>Multi-Day</option>
													<option>Single Day</option>
													<option>Callout</option>
												</select>
											</div>
										</div>
									</form>
								</div>
								<div className="card-footer">

									<div class="d-flex  justify-content-between">
										<a href="#!" class="btn btn-success">
											Save
											</a>
									</div>
								</div>
							</div>
						</div>
						<div class="col-md-6 col-lg-4">
							<div className="row">
							<div class="col">
								<div class="card m-b-30">
									<div class="card-body">
										<div class="row">
											<div class="col-lg-6 my-auto">
												<h2>Tasks Completed </h2>
												<p class="text-muted">Amount of tasks completed</p>
											</div>
											<div class="col-lg-6">
												<div style={{ overflow: "hidden", maxHeight: "210px", position: "relative" }}>
													<div id="chart-07" style={{ minHeight: 355 }}>
														<Chart options={options} series={options.series} type="radialBar" height={options.chart.height} />
													</div>
												</div>
											</div>
										</div>
									</div>

									<div class="card-footer">
										<div class="d-flex  justify-content-between">
											<a href="#!" class="btn btn-dark" onClick={() => this.props.history.push("/job/" + this.state.jobId + "/tasks")}>
												View Tasks
											</a>
										</div>
									</div>
								</div>
							</div>
							</div>

							<div className="row  m-b-20">
								<div class="col">
									<div class="card ">
										<div class="   text-center card-body">
											<div class=" text-center">
												<h3> 7 </h3>
											</div>
											<div class="text-overline ">Job Sheets</div>
										</div>
									</div>
								</div>
								<div class="col">
									<div class="card ">
										<div class="   text-center card-body">
											<div class=" text-center">
												<h3> 1 </h3>
											</div>
											<div class="text-overline ">Invoices</div>
										</div>
									</div>
								</div>
							</div>
							<div className="row">
								<div class="col ">
									<div class="card ">
										<div class="   text-center card-body">
											<div class=" text-center">
												<h3> 27 </h3>
											</div>
											<div class="text-overline ">Parts</div>
										</div>
									</div>
								</div>
								<div class="col">
									<div class="card ">
										<div class="   text-center card-body">
											<div class=" text-center">
												<h3> 0 </h3>
											</div>
											<div class="text-overline ">Files</div>
										</div>
									</div>
								</div>
							</div>
				</div>
					</div>
					<div className="row">
						<div class="col-md-12 col-lg-12">
							<div className="card m-b-40">
								<div class="card-body" style={{ minHeight: 515 }}>
									<Tabs defaultActiveKey="parts" id="uncontrolled-tab-example">
										<Tab eventKey="parts" title="Parts">
											<PartList parts={jobEntity.parts} isJunction></PartList>
										</Tab>
										<Tab eventKey="sheets" title="Sheets">
										</Tab>
										<Tab eventKey="files" title="Files">
											<div class="list-group list-group-flush ">
												<div class="list-group-item d-flex  align-items-center">
													<div class="m-r-20">
														<div class="avatar avatar-sm ">
															<div class="avatar-title bg-dark rounded">
																<i class="mdi mdi-24px mdi-file-document-box"></i>
															</div>
														</div>
													</div>
													<div class="">
														<div>Design Guide.pdf</div>
														<div class="text-muted">9 Mb</div>
													</div>

													<div class="ml-auto">
														<div class="dropdown">
															<a href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
																{" "}
																<i class="mdi  mdi-dots-vertical mdi-18px"></i>
															</a>

															<div class="dropdown-menu dropdown-menu-right">
																<button class="dropdown-item" type="button">
																	Action
													</button>
																<button class="dropdown-item" type="button">
																	Another action
													</button>
																<button class="dropdown-item" type="button">
																	Something else here
													</button>
															</div>
														</div>
													</div>
												</div>
												<div class="list-group-item d-flex  align-items-center">
													<div class="m-r-20">
														<div class="avatar avatar-sm ">
															<div class="avatar avatar-sm ">
																<div class="avatar-title  rounded">
																	<i class="mdi mdi-24px mdi-code-braces"></i>
																</div>
															</div>
														</div>
													</div>
													<div class="">
														<div>response.json</div>
														<div class="text-muted">15 Kb</div>
													</div>

													<div class="ml-auto">
														<div class="dropdown">
															<a href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
																{" "}
																<i class="mdi  mdi-dots-vertical mdi-18px"></i>
															</a>

															<div class="dropdown-menu dropdown-menu-right">
																<button class="dropdown-item" type="button">
																	Action
													</button>
																<button class="dropdown-item" type="button">
																	Another action
													</button>
																<button class="dropdown-item" type="button">
																	Something else here
													</button>
															</div>
														</div>
													</div>
												</div>
											</div>

										</Tab>
									</Tabs>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}


export default withRouter(connect()(JobDetail));


export const theme = createMuiTheme({
	palette: {
		primary: {
			main: "#000000"
		}
	},
	overrides: {
		MUIRichTextEditor: {
			root: {
				backgroundColor: "rgba(255, 255, 255, 0.15)",
				borderRadius: "4px",
				color: "white !important"
			},
			container: {
				display: "flex",
				color: "white",
				flexDirection: "column-reverse"
			},
			editor: {
				padding: "20px",
				paddingTop: 0,
				height: "200px",
				maxHeight: "200px",
				overflow: "auto",
				color: "white !important"
			},
			toolbar: {
				borderTop: "1px solid gray",
				color: "white !important"
			},
			placeHolder: {
				height: "200px",
				top: 0,
				paddingLeft: 20,
				width: "100%",
				position: "absolute",
				color: "white !important"
			},
			anchorLink: {
				color: "#333333",
				textDecoration: "underline"
			}
		}
	}
})
