import { Enviroment } from "../Enviroment/EnviromentVariables";
import { Locations } from "../Enviroment/Urls";

export default class CustomerRepository {
	constructor() {}

	static async GetCustomerPage(page, searchTerm = "", sortOrder = "", pageSize = 25) {
		try {
			var url = Enviroment.CUSTOMER_API_LOCATION + Locations.Customer_List.url;
			var customers = await fetch(url + "?page=" + page + "&searchTerm=" + searchTerm);

			if (!customers.ok) {
				return false;
			}

			return await customers.json();
		} catch (error) {
			console.error(error);
			return false;
		}
	}

	static async GetCustomer(customerId) {
		try {
			var url = Enviroment.CUSTOMER_API_LOCATION + Locations.Customer_Details.url + "/" + customerId;
			var customer = await fetch(url);

			if (!customer.ok) {
				return false;
			}

			return await customer.json();
		} catch (error) {
			console.error(error);
			return false;
		}
	}
}
