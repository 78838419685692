import React, {Component} from 'react';
import ColourItem from './ColourItem';

export default class ColourPicker extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.renderColours = this.renderColours.bind(this);
        this.colourSelected = this.colourSelected.bind(this);
    }

    colourSelected(colour) {
        this.setState({selectedColour: colour});
        this.props.onSelected(colour);
    }

    renderColours() {
        let colourItems = [];

        this.props.colours.forEach(element => {
            colourItems.push(<ColourItem colour={element} onClick={this.colourSelected} active={element === this.state.selectedColour}></ColourItem>)
        });

        return colourItems;
    }
    render() {
        let renderColours = this.renderColours();
        return(
            <div className="colourBox">
                {renderColours}
            </div>
        )
    }
}