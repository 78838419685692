import React, { Component } from "react";

export default class WizardPane {
	constructor(title, layout, tabId, step) {
		this.title = title;
		this.layout = layout;
		this.tabId = tabId;
		this.step = step;
	}
}
