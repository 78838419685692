import Types from "../actions/actionTypes";

const initialState = {
	taskShowing: false,
};
const kanbanReducer = (state = initialState, action) => {
	switch (action.type) {
		case Types.TASK_EDITED:
			return { selectedTask: action.task, taskShowing: true };
		case Types.TASK_CLOSED:
			return { taskShowing: false };
			return;
		default:
			return state;
	}
};
export default kanbanReducer;
