import React, { Component } from "react";

export default class JobSearchResult extends React.Component {
    render() {
        let job = this.props.job || {};
        return (
            <div className="list-group-item d-flex  align-items-center searchResult">
                <div className="m-r-20 resultLeftPanel">
                    <div className="itemType">Job</div>
                    <div className="avatar avatar-sm ">
                        <div className="avatar-title bg-dark rounded"><i className="mdi mdi-24px mdi-file-document-box"></i></div>
                    </div>
                </div>
                <div className="jobTaskResultBody">
                    <div className="text-content">
                        <div className="name">{job.name}</div>
                        <div className="text-muted">{job.state}</div>
                    </div>
                </div>
            </div>
        );
    }
}