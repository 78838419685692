import React, { Component } from "react";
import logo from "../../style/img/logo.svg";
import { connect } from "react-redux";
import actions from "../../actions/actions";

class SidebarHeader extends Component {
	constructor(props) {
		super(props);
		this.togglePinned = this.togglePinned.bind(this);
		this.state = { sidebarPinned: false };
		if (this.props.sidebarPinned) {
			this.state.sidebarPinned = this.props.sidebarPinned;
		}
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.sidebarPinned !== undefined) {
			this.setState({ sidebarPinned: nextProps.sidebarPinned });
		}
	}
	togglePinned() {
		this.props.dispatch(actions.pinsideBar());
	}

	render() {
		let headerText = <></>;
		if (this.state.sidebarPinned === true) {
			headerText = (
				<>
					<img className="admin-brand-logo" src={logo} width="40" alt="atmos Logo" />
					<span className="admin-brand-content">
						<a href="index.html">{this.props.title}</a>
					</span>
				</>
			);
		}
		return (
			<div className="admin-sidebar-brand">
				{headerText}
				<div className="ml-auto">
					<a href="#" className="admin-pin-sidebar btn-ghost btn btn-rounded-circle " onClick={this.togglePinned}></a>
					<a href="#" className="admin-close-sidebar"></a>
				</div>
			</div>
		);
	}
}

const mapStateToProps = function(state) {
	return state.layoutReducer;
};

export default connect(mapStateToProps)(SidebarHeader);
