import React from "react";
import { withRouter } from "react-router";

class SearchBox extends React.Component {
	constructor(props) {
		super(props);
		this.state = { searchText: "" };
		this.submit = this.submit.bind(this);
		this.onSearchTextEntered = this.onSearchTextEntered.bind(this);
		this.keyCapture = this.keyCapture.bind(this);
	}

	submit() {
		this.props.history.push({
			search: "?" + new URLSearchParams({ searchTerm: this.state.searchText }).toString(),
		});
		if (this.props.responseFunction) {
			this.props.responseFunction(this.state.searchText);
		}
	}

	onSearchTextEntered(e) {
		this.setState({ searchText: e.target.value });
	}

	keyCapture(input) {
		if (input.key === "Enter") {
			this.submit();
		}
	}

	render() {
		return (
			<div className="input-group input-group-flush mb-3">
				<input
					placeholder={this.props.placeHolder}
					type="search"
					onKeyDown={this.keyCapture}
					value={this.state.searchText}
					onChange={this.onSearchTextEntered}
					className="form-control form-control-lg search form-control-prepended"
				/>
				<div className="input-group-prepend">
					<div className="input-group-text">
						<i className="mdi mdi-magnify"></i>
					</div>
				</div>
			</div>
		);
	}
}

export default withRouter(SearchBox);
