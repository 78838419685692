import React, { Component } from "react";
import TextInput from "../../components/Inputs/TextInput";
import TextField from "@material-ui/core/TextField";
import PartCell from "../../components/Parts/PartCell";
import { DatePicker, TimePicker, DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { Button } from "@material-ui/core";

export default class JobSubmit extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<div className="jobSubmit align-middle">
				<h3>
					<span className="align-middle">Confirm</span>
				</h3>
				<div className="jobSubmitContents">
					Confirm the creation of this job, this will cost aprox £#.##.
					<div className="jobSubmitButton">
						<Button variant="contained" color="primary" onClick={this.props.onSubmit}>
							Create Job
						</Button>
						<Button variant="contained" color="secondary">
							Cancel
						</Button>
					</div>
				</div>
			</div>
		);
	}
}
