import { Enviroment } from "../Enviroment/EnviromentVariables";
import { Locations } from "../Enviroment/Urls";
import FileRepository from "./FileRepository";

export default class PartRepository {
	constructor() {}

	static async GetPartPage(page, searchTerm = "", sortOrder = "", pageSize = 25) {
		try {
			var url = Enviroment.PART_API_LOCATION + Locations.Part_List.url;
			var parts = await fetch(url + "?page=" + page + "&searchTerm=" + searchTerm);

			if (!parts.ok) {
				return false;
			}

			return await parts.json();
		} catch (error) {
			console.error(error);
			return false;
		}
	}

	static async UploadImage(image, partId) {
		try {
			var uploadedFile = await FileRepository.UploadFile(image);

			if (!uploadedFile) {
				return { status: "error" };
			}
            
			var methodBody = {
				imageId: uploadedFile.objectId,
				location: uploadedFile.location,
				partId: partId,
			};

            var url = Enviroment.PART_API_LOCATION + Locations.Parts_Image_Upload.url;
            var methodString = JSON.stringify(methodBody);
			var partImageResponse = await fetch(url, {
                method: "POST",
                
                body: methodString, 
                 headers: {
                    'Content-Type': 'application/json'
                  },
            });
            
            if(partImageResponse.ok)
            {
                return uploadedFile;
            }
            
            return { status: "error" }
		} catch (error) {
            console.log(error);
            return { status: "error" }
        }
	}

	static async GetPartDetails(partId) {
		try {
			var url = Enviroment.PART_API_LOCATION + Locations.Parts_Details.url + "/" + partId;
			var part = await fetch(url);

			if(part.ok)
            {
                return await part.json();
            }
            
            return { status: "error", code: part.status }
		} catch (error) {
			console.error(error);
			return false;
		}
	}
}
