import React, { Component } from "react";
import logo from "../../style/img/logo.svg";
import defaultImage from "../../style/img/logo.svg";
import { withRouter } from "react-router-dom";
import { useHistory } from "react-router-dom";
import FileRepository from "../../repositories/FileRepository";
import Rating from "@material-ui/lab/Rating";

class PartCell extends Component {
	constructor(props) {
		super(props);

		this.state = {
			part: props.part,
		};

		if (props.part.image === undefined) {
			this.state.part.image = defaultImage;
		}
		this.goToPartDetail = this.goToPartDetail.bind(this);
	}

	async GetThumbnailImage() {
		if (!this.props.part.images) {
			return defaultImage;
		}

		var firstImage = this.props.part.images[0];
        if (!firstImage)
         return defaultImage;

        //var thumbnail = await FileRepository.GetThumbnailFromStorage(firstImage.location);
        //if(thumbnail == false)
        //    return defaultImage;

        //console.log(thumbnail);
		return firstImage.location;
	}

	async GetStock() {}

	goToPartDetail() {
		this.props.history.push("/part/details/" + this.state.part.objectId);
	}

	async componentWillMount() {
		var image = await this.GetThumbnailImage();
		this.setState({ thumbnail: image });
	}

	render() {
		return (
			<div className="card m-b-30 partCell">
				<div className="card-body">
					<div className="card-controls">
						<a href="#" className="">
							<i className="mdi mdi-bookmark" />
						</a>
					</div>
					<div className="text-center">
						<img src={this.state.thumbnail} width="150px" height="150px" alt="" />
					</div>
				</div>

				<div className="bg-dark text-center rounded-bottom text-white p-all-15">
					<Rating value={this.state.part.rating} readonly />
					<h5 className="font-primary" onClick={this.goToPartDetail}>
						{this.state.part.name}
					</h5>
					<p className="opacity-75 m-0">{this.state.part.category}</p>

					<h5 className="font-primary p-t-10">{this.state.part.stock}/50</h5>
				</div>
			</div>
		);
	}
}

export default withRouter(PartCell);
