import React, { Component } from "react";
import TextInput from "../../components/Inputs/TextInput";
import TextField from "@material-ui/core/TextField";
import PartCell from "../../components/Parts/PartCell";
import { DatePicker, TimePicker, DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { Button } from "@material-ui/core";
import DateHelper from "../../helpers/DateHelper";
import { Draggable } from "react-beautiful-dnd";
import actions from "../../actions/actions";
import { connect } from "react-redux";

class KanBanTask extends Component {
	constructor(props) {
		super(props);
		this.selected = this.selected.bind(this);
	}

	selected() {
		this.props.dispatch(actions.taskEdited(this.props.task));
	}

	render() {
		let task = this.props.task;
		return (
			<div className="kanban-item" onClick={this.selected} ref={this.props.innerRef} {...this.props.provided.draggableProps} {...this.props.provided.dragHandleProps}>
				<div className="card">
					<div className="kanban-ribbion bg-danger"></div>
					<div className="card-header">
						<span className="kanban-item-title"> {task.name}</span>
						<div className="row m-t-5">
							<div className="col-auto my-auto">
								<span className="badge  badge-soft-primary">
									{" "}
									{DateHelper.MediumDateFormatter(task.startTime)} - {DateHelper.MediumDateFormatter(task.endTime)}{" "}
								</span>
							</div>
						</div>
					</div>
					<div className="card-body ">
						<div className="row">
							<div className="col  my-auto">
								<a href="#" className="text-muted btn-light no-shadow btn btn-sm">
									{" "}
									<i className="mdi mdi-account-plus "></i> Add{" "}
								</a>
							</div>
							<div className="col-auto text-right">
								<div className="avatar-group">
									<div className="avatar avatar-xs ">
										<span className="avatar-title bg-dark rounded-circle">R</span>
									</div>
									<div className="avatar avatar-xs ">
										<span className="avatar-title bg-danger rounded-circle">K</span>
									</div>
									<div className="avatar avatar-xs ">
										<span className="avatar-title bg-warning rounded-circle">AB</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default connect()(KanBanTask);
