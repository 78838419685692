import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PartList from "../../components/Parts/PartList";
import actions from "../../actions/actions";
import PartRepository from "../../repositories/PartRepository";
import ImageUploader from "../../components/Images/ImageUploader";
import FileRepository from "../../repositories/FileRepository";

class PartDetail extends Component {
	constructor(props) {
		super(props);
		this.state = { part: {} };
		this.GetPart = this.GetPart.bind(this);
		this.submitFunction = this.submitFunction.bind(this);
	}

	async componentWillMount() {
		let partId = this.props.match.params.id;
		await this.GetPart(partId);
	}

	async GetPart(partId) {
		var part = await PartRepository.GetPartDetails(partId);
		if (part === false) {
			return;
        }        
        
        console.log(part);
		this.setState({ part });
    }
    
    async GetPartImages(imageObjects) {
        if(!imageObjects || imageObjects == null)
            return;

        var newImageObjects = [];
        var promises = [];
        for (let i = 0; i < imageObjects.length; i++) {
            const imageObject = imageObjects[i];
            
            promises.push(this.GetPartImage(imageObject));
        }
        var newImageObjects = await Promise.allSettled(promises);
        var part = this.state.part;
        part.images = newImageObjects;
        this.setState(part);
    }

    async GetPartImage(imageObject) {
        if(!imageObject)
        return;

        var imageBlob = await FileRepository.GetFileFromStorage(imageObject.location);
        imageObject.imageBlob = imageBlob;
        return imageObject;
    }

	async submitFunction(image) {
        console.log(image);
        PartRepository.UploadImage(image, this.state.part.objectId)
        .then((response) => {
			if(response.status) {				
				this.props.dispatch(actions.addErrorNotification("Error uploading image", "We've recieved an unknown error"));
			}
		}); 
    }

	render() {
		let partEntity = this.state.part;
		return (
			<div className="partPage">
				<div className="row">
					<div className="col-6 partImages">
						<ImageUploader onSubmit={this.submitFunction} />
					</div>
					<div className="col-6 bg-light partDescription">
						<h1 className="Title">{partEntity.name}</h1>
						<div>{partEntity.category}</div>
						<div>{partEntity.subCategory}</div>
						<div>{partEntity.description}</div>
					</div>
				</div>
			</div>
		);
	}
}

export default withRouter(connect()(PartDetail));
