import React, { Component } from 'react';
import logo from '../../style/img/logo.svg'
import HeaderProfile from './headerProfile';
import NotificationButton from './notificationButton';
import actions from '../../actions/actions';

import { connect } from 'react-redux'
class HeaderBar extends Component {
    constructor(props) {
        super(props);

        this.showSearch = this.showSearch.bind(this);
    }

    showSearch() {
        this.props.dispatch(actions.showSearchBar(true))
    }

    render() {
        return (<header className="admin-header">

            <a href="#" className="sidebar-toggle"> </a>

            <nav className=" mr-auto my-auto">
                <ul className="nav align-items-center">

                    <li className="nav-item">
                        <a className="nav-link" onClick={this.showSearch}   >
                            <i className=" mdi mdi-magnify mdi-24px align-middle"></i>
                        </a>
                    </li>
                </ul>
            </nav>
            <nav className=" ml-auto">
                <ul className="nav align-items-center">

                    <li className="nav-item">
                        <div className="dropdown">
                            <NotificationButton hasNotification={true}></NotificationButton>

                        </div>
                    </li>
                    <li className="nav-item dropdown ">
                        <HeaderProfile userInitial="JS"></HeaderProfile>
                    </li>

                </ul>

            </nav>
        </header>);
    }
}

const mapStateToProps = function (state) {
    return state.layoutReducer;
}

export default connect(
    mapStateToProps,
)(HeaderBar)