var dev = {
	Parts_Fetching: { url: "Part", method: "GET" },
	Part_List: { url: "Part", method: "GET" },
	Parts_Details: { url: "Part", method: "GET" },
	Parts_Creation: { url: "Part", method: "POST" },
	Parts_Deletion: { url: "Part", method: "DELETE" },
	Parts_Updating: { url: "Part", method: "PUT" },
	Parts_Image_Upload: { url: "Part/UploadImage", method: "POST" },
	File_Details: { url: "File", method: "GET" },
	File_Uploading: { url: "OrganisationFile", method: "POST" },
	Tasks_States: { url: "TaskState", method: "GET" },
	Tasks_ByState: { url: "JobTask/byState", method: "GET" },
	Tasks_ByDate: { url: "JobTask/ByDate", method: "GET" },
	Tasks_ByGroup: { url: "JobTask/ByGroup", method: "GET" },
	Task_Creation: { url: "JobTask", method: "POST" },
	Tasks_UpdateState: { url: "JobTask/UpdateTaskState", method: "PUT" },
	Tasks_Update: { url: "JobTask", method: "Put" },
	Job_List: { url: "Job", method: "GET" },
	Job_Search: { url: "Job/search", method: "GET" },
	Job_Details: { url: "Job", method: "GET" },
	Job_Creation: { url: "Job", method: "POST" },
	Customer_Details: { url: "Customer", method: "GET" },
	Customer_List: { url: "Customer", method: "GET" },
	Customer_Create: { url: "Customer", method: "POST" },
	Customer_Update: { url: "Customer", method: "PUT" },
	Property_Details: { url: "Customer", method: "GET" },
	Property_List: { url: "Customer", method: "GET" },
	Property_Create: { url: "Customer", method: "POST" },
	Property_Update: { url: "Customer", method: "PUT" },
	TaskGroups: { url: "TaskGroup", method: "GET" },
	TaskGroup_GetAll: { url: "TaskGroup", method: "GET" },
	TaskGroup_Creation: { url: "TaskGroup", method: "POST" },
	TaskGroup_Update: { url: "TaskGroup", method: "PUT" },
	Universal_Search: { url: "Job/searchAll", method: "GET" },
};

export const Locations = dev;
