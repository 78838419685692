import React, { Component } from "react";
import actions from "../../actions/actions";
import { connect } from "react-redux";

class NotificationPopup extends Component {
	constructor(props) {
		super(props);

		this.state = {
			progress: 0,
			title: this.props.notification.title,
			description: this.props.notification.description,
			
			timeout: this.props.notification.timeout ? this.props.notification.timeout : 5000
		};
		
	}

	componentDidMount() {
		setTimeout(() => this.setState({ progress: 100 }), 20);

		setTimeout(() => this.props.dispatch(actions.removeNotification(this.props.notification.id)), this.state.timeout);
	}

	status() {
		var notificationState = this.props.notification.status;
		switch (notificationState) {
			case 0:
				return "success";
			case 1:
				return "warning";
			case 2:
				return "danger";
			case 3:
				return "info";
			default:
				return "default";
		}
	}

	render() {
		var statusColour = this.status();
		let notification = this.props.notification;
		console.log(this.state.progress);
		console.log(this.state.timeout);
		return (
			<div className="bootstrap-notify alert">
				<div className="progress">
					<div className={"progress-bar bg-" + statusColour} style={{ width: "" + this.state.progress + "%", transition: "width " + this.state.timeout / 1000 + "s" }}></div>
				</div>
				<div className="media">
					<div className="avatar m-r-10 avatar-sm">
						<div className={"avatar-title rounded-circle bg-" + statusColour}>
							<span className={"icon mdi mdi-"+notification.icon}></span>
						</div>
					</div>
					<div className="media-body">
						<div className="font-secondary">{notification.title}</div>
						<span className="opacity-75">{notification.description}</span>
					</div>
					<a href="#" target=""></a>
					<button type="button" aria-hidden="true" className="close">
						<span>x</span>
					</button>
				</div>
			</div>
		);
	}
}

export default connect()(NotificationPopup);