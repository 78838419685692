import React, { Component } from "react";
import NotificationPopup from "./notificationPopup";
import { connect } from "react-redux";
import uuid from "uuid";


class NotificationContainer extends Component {
	render() {
		console.log(this.props);
		var list = this.props.notifications.map(not => {
			return <NotificationPopup notification={not} key={not.id}/>;
		  });

		return <div className="notificationContainer">{list}</div>;
	}
}

const mapStateToProps = (state) => ({
	notifications: state.notificationReducer.notifications
});

export default connect(mapStateToProps)(NotificationContainer);
