import React, { Component } from "react";
import DateHelper from "../../helpers/DateHelper";

export default class UserRow extends Component {
	constructor(props) {
		super(props);
	}
	render() {
		let user = this.props.user;
		return (
			<div className="list-group-item d-flex  align-items-center">
				<div className="m-r-20">
					<div className="avatar avatar-sm ">
						<img src={user.userImage} className="avatar-img avatar-sm rounded-circle" />
					</div>
				</div>
				<div>
					<div>
						{user.firstName} {user.lastName}
					</div>
					<div className="text-muted">{user.jobTitle}</div>
				</div>

				<div className="ml-auto">
					<a href="#" className="btn btn-danger" onClick={this.props.OnRemove(user.id)}>
						Remove
					</a>
				</div>
			</div>
		);
	}
}
