import Types from "../actions/actionTypes";

const initialState = {
	activeTab: undefined,
	tabData: [],
};
const wizardReducer = (state = initialState, action) => {
	switch (action.type) {
		case Types.WIZARD_TAB_CHANGED:
			return { activeTab: action.tabId };
		case Types.WIZARD_TAB_SUBMITTED:
			let tabData = state.tabData;
			tabData[action.tabId] = action.tabData;

			return { ...state, tabData };
		default:
			return state;
	}
};
export default wizardReducer;
