import { Enviroment } from "../Enviroment/EnviromentVariables";
import { Locations } from "../Enviroment/Urls";

export default class JobRepository {
	static async GetJobPage(page, sortOrder = "", pageSize = 25) {
		try {
			var url = Enviroment.JOB_API_LOCATION + Locations.Job_List.url;
			var jobs = await fetch(url + "?page=" + page);

			if (!jobs.ok) {
				return false;
			}

			return await jobs.json();
		} catch (error) {
			console.error(error);
			return false;
		}
	}

	static async SearchJobs(page, searchTerm = "", pageSize = 25) {
		try {
			var url = Enviroment.JOB_API_LOCATION + Locations.Job_Search.url;
			var jobs = await fetch(url + "?page=" + page + "&searchQuery=" + searchTerm);

			if (!jobs.ok) {
				return false;
			}

			return await jobs.json();
		} catch (error) {
			console.error(error);
			return false;
		}
	}

	static async GetJobDetails(jobId) {
		try {
			var url = Enviroment.JOB_API_LOCATION + Locations.Job_Details.url + "/" + jobId;
			var job = await fetch(url);

			if (!job.ok) {
				return false;
			}

			return await job.json();
		} catch (error) {
			console.error(error);
			return false;
		}
	}

	static async CreateJob(job) {
		try {
			var url = Enviroment.JOB_API_LOCATION + Locations.Job_Creation.url;
			var body = JSON.stringify(job);
			console.log(body);
			var job = await fetch(url, {
				method: Locations.Job_Creation.method,
				body: body,
				headers: {
					"Content-Type": "application/json"
				},
			});

			if (!job.ok) {
				return false;
			}

			return await job.json();
		} catch (error) {
			console.error(error);
			return false;
		}
	}
}
