import React, { Component } from "react";

export default class WizardTabButton extends Component {
	constructor(props) {
		super(props);
	}
	render() {
		let styleClass = "nav-link";

		if (this.props.className) {
			styleClass += " " + this.props.className;
		}

		if (this.props.active) {
			styleClass += " active";
		}

		let onClick = this.props.onClick;
		return (
			<li className="nav-item">
				<a className={styleClass} href="#" onClick={() => this.props.onClick(this.props.tabId)}>
					{this.props.children}
				</a>
			</li>
		);
	}
}
