import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './style/fonts/feather/feather-icons.css'
import './style/fonts/materialdesignicons/materialdesignicons.min.css'

import { Provider } from 'react-redux'
import { createStore } from 'redux'
import rootReducer from './reducer/rootReducer';

const store = createStore(rootReducer);


ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);
