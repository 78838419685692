import React, { Component } from "react";
import logo from "./logo.svg";
import "./style/css/atmos.css";
import Sidebar from "./components/Sidebar/sidebar";
import HeaderBar from "./components/HeaderBar/headerBar";
import { connect } from "react-redux";
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import Home from "./pages/home";
import NotificationContainer from "./components/Notifications/notificationContainer";
import SearchPanel from "./components/SearchBox/SearchPanel";
import profile from "./pages/User/profile";
import PartIndex from "./pages/Parts/PartIndex";
import PartDetails from "./pages/Parts/PartDetails";
import WizardTestPage from "./pages/TestPages/WizardTestPage";
import Board from "./components/KanBan/Board";
import JobIndex from "./pages/Jobs/JobIndex";
import JobTasks from "./pages/Tasks/JobTasks";
import JobDetail from "./pages/Jobs/JobDetail";
import CustomerCreation from "./pages/Customers/CustomerCreation"
import CustomerIndex from "./pages/Customers/CustomerIndex";

class App extends Component {
	constructor(props) {
		super(props);

		this.state = {};
		if (this.props.sidebarPinned) {
			this.state.sidebarPinned = this.props.sidebarPinned;
		}
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.sidebarPinned !== undefined) {
			this.setState({ sidebarPinned: nextProps.sidebarPinned });
		}
	}
	render() {
		let bodyClass = "";
		if (this.state.sidebarPinned === true) {
			bodyClass = "sidebar-pinned";
		}

		return (
			<Router>
				<body className={bodyClass}>
					<DashboardRoute exact path="/" component={Home} />
					<DashboardRoute exact path="/profile" component={profile} />
					<DashboardRoute exact path="/parts" component={PartIndex} />
					<DashboardRoute exact path="/wizard" component={WizardTestPage} />
					<DashboardRoute exact path="/jobs" component={JobIndex} />
					<DashboardRoute exact path="/job/:jobId/tasks" component={JobTasks} />
					<DashboardRoute exact path="/job/:jobId/details" component={JobDetail} />
					<DashboardRoute exact path="/part/details/:id" component={PartDetails} />

                    <DashboardRoute exact path="/customer/create" component={CustomerCreation}/>
                    <DashboardRoute exact path="/customer" component={CustomerIndex}/>
                    <DashboardRoute exact path="/customer/details/:id" component={CustomerIndex}/>
				</body>
			</Router>
		);
	}
}

const OrganisationLayout = ({ children, ...rest }) => {
	return (
		<>
			<Sidebar></Sidebar>
			<main className="admin-main">
				<NotificationContainer />
				<HeaderBar></HeaderBar>
				<section className="admin-content">{children}</section>
			</main>

			<SearchPanel />
		</>
	);
};

const DashboardRoute = ({ component: Component, ...rest }) => {
	return (
		<Route
			{...rest}
			render={matchProps => (
				<OrganisationLayout>
					<Component {...matchProps} />
				</OrganisationLayout>
			)}
		/>
	);
};

const mapStateToProps = function(state) {
	return state.layoutReducer;
};

export default connect(mapStateToProps)(App);
