const Types = {
    SIDEBAR_TOGGLE: "SIDEBAR_TOGGLE",
    SIDEBAR_SHOWN: "SIDEBAR_SHOWN",
    SEARCHBAR_SHOWN: "SEARCHBOX_SHOWN",
    SHOW_LIST_MODAL: "SHOW_LIST_MODAL",
    NOTIFICATION_CREATED: "NOTIFICATION_CREATED",
    NOTIFICATION_DELETED: "NOTIFICATION_DELETED",
    WIZARD_TAB_CHANGED: "WIZARD_TAB_CHANGED",
    WIZARD_TAB_SUBMITTED: "WIZARD_TAB_SUBMITTED",
    TASK_DRAG_START: "TASK_DRAG_START",
    TASK_DRAG_END: "TASK_DRAG_END",
    TASK_DROPPED: "TASK_DROPPED",
    TASK_HOVER: "TASK_HOVER",
    TASK_EDITED: "TASK_EDITED",
    TASK_CLOSED: "TASK_CLOSED"
}
export default Types;