import React, { Component } from 'react';
import Badge from '../Badge/Badge';
export default class UserHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: props.userProfile
        };
    }
    render() {
        return (
            <div className="row p-b-60 p-t-60">
                <div className="col-md-6 text-white p-b-30">
                    <div className="media">
                        <div className="avatar avatar mr-3">
                            <img src="/images/users/user-2.jpg" className="rounded-circle" alt="" />
                        </div>
                        <div className="media-body">
                            <h1>{this.state.user.fullName}</h1>
                            <div className="text-overline opacity-75">{this.state.user.jobTitle}</div>
                            <p className="opacity-75">{this.state.user.summary}</p>
                        </div>
                    </div>

                </div>
                <div className="col-md-6 text-md-right text-white">
                    <div>
                        <div className="text-overline opacity-75 m-b-10">on Teams</div>
                        <Badge text="Development" colour="danger"></Badge>
                        <Badge text="UI/UX" colour="info"></Badge>
                    </div>

                    <p className="m-t-10">
                        <a href="#" className="btn btn-white-translucent"> 👑 Infinity Plan</a>
                    </p>
                </div>

            </div>);
    }
}
