import { Enviroment } from "../Enviroment/EnviromentVariables";
import { Locations } from "../Enviroment/Urls";

export default class TaskRepository {
	static async GetStates(jobId) {
		try {
			var url = Enviroment.JOB_API_LOCATION + Locations.Tasks_States.url + "?jobId=" +jobId;
			var states = await fetch(url);

			if (!states.ok) {
				return false;
			}

			return await states.json();
		} catch (error) {
			console.error(error);
			return false;
		}
	}

	static async GetTaskByState(jobId, state, page, sortOrder = "") {
		try {
			var url = Enviroment.JOB_API_LOCATION + Locations.Tasks_ByState.url + "/" + jobId;
			var tasks = await fetch(url + "?state=" + state + "&page=" + page);

			if (!tasks.ok) {
				return false;
			}

			return await tasks.json();
		} catch (error) {
			console.error(error);
			return false;
		}
	}

	static async UpdateTaskByState(taskId, state) {
		try {
			var url = Enviroment.JOB_API_LOCATION + Locations.Tasks_UpdateState.url + "/" + taskId;
			var updateResult = await fetch(url + "?newState=" + state, { method: Locations.Tasks_UpdateState.method });

			if (updateResult.ok) {
				return true;
			}
			return false;
		} catch (error) {
			console.error(error);
			return false;
		}
    }
    
    
	static async GetTaskByDate(jobId, startDate, endDate) {
		try {
			var url = Enviroment.JOB_API_LOCATION + Locations.Tasks_ByDate.url + "/" + jobId + "?start=" + startDate.toISOString() + "&end=" + endDate.toISOString();
			var tasks = await fetch(url);

			if (!tasks.ok) {
				return false;
			}

			return await tasks.json();
		} catch (error) {
			console.error(error);
			return false;
		}
    }
    
    static async GetTaskByGroup(jobId, groupId) {
		try {
			var url = Enviroment.JOB_API_LOCATION + Locations.Tasks_ByGroup.url + "/" + jobId + "?groupid=" + groupId;
			var tasks = await fetch(url);

			if (!tasks.ok) {
				return false;
			}

			return await tasks.json();
		} catch (error) {
			console.error(error);
			return false;
		}
    }
    
    static async CreateTask(task) {
		try {
			var url = Enviroment.JOB_API_LOCATION + Locations.Task_Creation.url;
			var body = JSON.stringify(task);
			var task = await fetch(url, {
				method: Locations.Task_Creation.method,
				body: body,
				headers: {
					"Content-Type": "application/json"
				},
			});

			if (!task.ok) {
				return false;
			}

			return await task.json();
		} catch (error) {
			console.error(error);
			return false;
		}
    }
    
    static async UpdateTask(task) {
		try {
			var url = Enviroment.JOB_API_LOCATION + Locations.Tasks_Update.url;
			var body = JSON.stringify(task);
			var task = await fetch(url, {
				method: Locations.Tasks_Update.method,
				body: body,
				headers: {
					"Content-Type": "application/json"
				},
			});

			if (!task.ok) {
				return false;
			}

			return await task.json();
		} catch (error) {
			console.error(error);
			return false;
		}
	}
}
