export default class Task {
    constructor(name, description, state, startTime, endTime, job, colour = "#3ecf8e") {
        this.name = name;
        this.description = description;
        this.state = state;
        this.startTime = startTime;
        this.endTime = endTime;
        this.parentJob = job;
        this.TimelineColor = colour;
    }

    addUsers(users) {
        
    }
}