export default class EventObject {
    constructor(task) {
        this.start = task.startTime;
        this.title = task.name;
        this.end = task.endTime;
        this.objectId = task.objectId;
        this.editable = true;
        this.startEditable= true;
        this.durationEditable = true;
        this.task = task;
    }
}