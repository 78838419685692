import React, { Component } from "react";
import TextInput from "../../components/Inputs/TextInput";
import TextField from "@material-ui/core/TextField";
import PartCell from "../../components/Parts/PartCell";
import { DatePicker, TimePicker, DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import Button from "../../components/Buttons/Button";
import ImageUploader from "../../components/Images/ImageUploader";
import TabBar from "../../components/Inputs/TabBar";
import Tab from "../../components/Inputs/Tab";

export default class CustomerCreation extends Component {
	constructor(props) {
		super(props);
		this.onTitleUpdated = this.onTitleUpdated.bind(this);
		this.validate = this.validate.bind(this);
		this.onDescriptionUpdated = this.onDescriptionUpdated.bind(this);
		this.detailsTab = this.detailsTab.bind(this);
		this.tabChanged = this.tabChanged.bind(this);

		this.imageTab = this.imageTab.bind(this);
		this.state = {
			siteDisabled: true,
			titleValid: true,
			currentTab: "details",
		};
		if (this.props.tabPane) {
			this.state.tabId = this.props.tabPane.tabId;
		}

		if (this.props.state) {
			this.state = this.props.state;
		}
	}

	onTitleUpdated(e) {
		let titleValid = true;
		if (e.target.value === undefined || e.target.value === "") {
			titleValid = false;
		}
		this.setState({ title: e.target.value, titleValid });
		var currentState = { ...this.state, title: e.target.value, };
		this.props.onUpdated(currentState);
		this.validate();
	}

	onDescriptionUpdated(e) {
		this.setState({ description: e.target.value });
		var currentState = { ...this.state, description: e.target.value, };
		this.props.onUpdated(currentState);
		this.validate();
	}


	validate() {
		if (this.state.title === "" || this.state.title === undefined) {
			this.props.onValid(this.state.tabId, false);
			return;
		}
		if (this.state.endDate < this.state.startDate) {
			this.props.onValid(this.state.tabId, false);
			return;
		}

		this.props.onValid(this.state.tabId, true);
	}

	detailsTab() {
		if (this.state.currentTab != "details") return <></>;

		let titleHelperText = "";
		if (!this.state.titleValid) {
			titleHelperText = "Title must have a value";
		}

		return (
			<div className="col-lg-6 col-md-12">
				<div className="row">
					<TextField variant="outlined" label="Title" required error={!this.state.titleValid} helperText={titleHelperText} onChange={this.onTitleUpdated} value={this.state.title} />
				</div>
				<div className="row">
					<TextField variant="outlined" label="Email" onChange={this.onDescriptionUpdated} value={this.state.email} />
				</div>
				<div className="row">
					<TextField variant="outlined" label="Phone Number" onChange={this.onDescriptionUpdated} value={this.state.email} />
				</div>
				<div className="row">
					<TextField variant="outlined" label="Website" onChange={this.onDescriptionUpdated} value={this.state.email} />
				</div>
				<div className="row">
					<TextField variant="outlined" label="Category" onChange={this.onDescriptionUpdated} value={this.state.email} />
				</div>
				<div className="row">
					<TextField variant="outlined" label="Description" multiline rows={6} onChange={this.onDescriptionUpdated} value={this.state.description} />
				</div>
				<div className="row">
					<TextField variant="outlined" label="Notes" multiline rows={6} onChange={this.onDescriptionUpdated} value={this.state.description} />
				</div>
			</div>
		);
	}

	imageTab() {
		if (this.state.currentTab != "image") return <></>;
		return <ImageUploader onSubmit={this.submitFunction} />;
	}

	tabChanged(id) {
		this.setState({ currentTab: id });
	}

	render() {
		let detailsTab = this.detailsTab();
		let imageTab = this.imageTab();

		return (
			<div className="jobCreationSummary align-middle">
				<h3>
					<span className="align-middle">Customer Creation</span>
				</h3>
				<div className="row">
					<div className="col-lg-1 col-md-0"></div>
					<div className="col-lg-10 col-md-12">
						<div className="card">
							<div className="card-header">
								<TabBar tabs={[new Tab("Details", "details"), new Tab("Contacts", "contacts"), new Tab("Image", "image")]} tabChanged={this.tabChanged}></TabBar>
							</div>
							<div className="tab-content">
								<div class="tab-pane active" id="details" role="tabpanel" aria-labelledby="details-tab">
									{detailsTab}
								</div>
								<div class="tab-pane active" id="image" role="tabpanel" aria-labelledby="image-tab">
									{imageTab}
								</div>
							</div>
							<div className="card-footer">
								<Button className="btn-primary" href="/wizard">
									Next
								</Button>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
