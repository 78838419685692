import React, { Component } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import { Enviroment } from "../../Enviroment/EnviromentVariables";

export default class SelectionBox extends Component {
	constructor(props) {
		super(props);
		this.state = { searchTerm: "", options: [] };
		this.getData = this.getData.bind(this);
	}

	async getData(searchTerm) {
		if (searchTerm == "") {
			this.setState({ options: [], loading: false });
			return;
		}
		this.setState({ loading: true });
		try {
			let response = await this.props.searchFunction(1, searchTerm, "", 100);
			if (response === false) {
				this.setState({ failed: true, loading: false });
				return;
			}
			let listData = response.listData.map(option => {
				const selectedVariable = this.props.mapping(option);
				const firstLetter = selectedVariable[0].toUpperCase();
				return {
					firstLetter: /[0-9]/.test() ? "0-9" : firstLetter,
					...option,
				};
			});
			var sortedData = listData.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter));
			this.setState({ options: sortedData, loading: false });
		} catch (error) {
			this.setState({ failed: true, loading: false });
			return;
		}
	}

	render() {
		let loading = this.state.loading;
		return (
			<Autocomplete
				loading={this.state.loading}
				noOptionsText={this.props.noOptionsText}
				disableCloseOnSelect={this.props.disableCloseOnSelect}
				clearOnEscape
				options={this.state.options}
				disabled={this.props.disabled}
				groupBy={option => option.firstLetter}
				getOptionLabel={this.props.mapping}
				onChange={this.props.onSelected}
				getOptionDisabled={this.props.filter}
				value={this.props.value}
				onInputChange={async (event, searchTerm) => this.getData(searchTerm)}
				renderOption={this.props.renderOption}
				renderInput={params => (
					<TextField
						{...params}
						label={this.props.label}
						value={this.props.searchTerm}
						fullWidth
						variant="outlined"
						InputProps={{
							...params.InputProps,
							endAdornment: (
								<>
									{loading ? <CircularProgress color="inherit" size={20} /> : null}
									{params.InputProps.endAdornment}
								</>
							),
						}}
					/>
				)}
			/>
		);
	}
}
