import { UserAgentApplication } from "msal";

const dev ={
    Api_Location: "https://localhost:44339/",
    PART_API_LOCATION:"https://localhost:44329/",
    CUSTOMER_API_LOCATION:"https://localhost:44330/",
    FILE_URL_LOCATION: "https://localhost:44339/",
    JOB_API_LOCATION:"https://localhost:44336/",
    MY_LOCATION: "http://localhost:3000"

} 
const staging  ={
    Api_Location: "https://parts.jackskitt.com/",
    PART_API_LOCATION:"https://parts.jackskitt.com/",
    FILE_URL_LOCATION: "https://files.jackskitt.com/",
    JOB_API_LOCATION:"https://jobs.jackskitt.com/",
    CUSTOMER_API_LOCATION:"https://customer.jackskitt.com/",
    MY_LOCATION: "https://dashboard.jackskitt.com"
} 

const dev_msalConfig = {
    auth: { 
        clientId: "05791c1b-6194-49a6-926a-1c005eab18aa",
        redirectUri: dev.MY_LOCATION+"/loginResponse",
        authority: "https://login.microsoftonline.com/common",
        postLogoutRedirectUri: dev.MY_LOCATION+"/logoutResponse"
    },
    cache: {
        cacheLocation: "localStorage"

    }
}

export const Enviroment = staging;
export const MsalConfig = dev_msalConfig;
export const Msal = new UserAgentApplication(MsalConfig);