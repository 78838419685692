import Types from "../actions/actionTypes";

const initialState = {
	sidebarPinned: true,
	searchBarShown: false,
};
const layoutReducer = (state = initialState, action) => {
	switch (action.type) {
		case Types.SIDEBAR_TOGGLE:
			return { sidebarPinned: !state.sidebarPinned };
		case Types.SIDEBAR_SHOWN:
			return { sidebarShown: action.sidebarShown };
		case Types.SEARCHBAR_SHOWN:
			return { searchBarShown: action.searchBarShown };
		case Types.SHOW_LIST_MODAL:
			return { listModalShown: action.showListModal };

		default:
			return state;
	}
};
export default layoutReducer;
