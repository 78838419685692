import React, { Component } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

export default class ImageUploader extends Component {
    constructor(props) {
        super(props);
        this.renderCropper = this.renderCropper.bind(this);
        this.makeClientCrop = this.makeClientCrop.bind(this);
        this.submit = this.submit.bind(this);
        this.onSelectFile = this.onSelectFile.bind(this);

        this.state = {
            src: null,
            crop: {
                unit: '%',
                width: 30,
                aspect: 1,
            },
        };
    }

    onSelectFile = e => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                console.log(reader)
                this.setState({ src: reader.result })
            }
            );
            this.setState({ fileName: e.target.files[0].name })
            reader.readAsDataURL(e.target.files[0]);
        }
    }
   
      // If you setState the crop in here you should return false.
      onImageLoaded = image => {
        this.imageRef = image;
      };
    
      onCropComplete = crop => {
        this.makeClientCrop(crop);
      };
    
      onCropChange = (crop, percentCrop) => {
        // You could also use percentCrop:
        // this.setState({ crop: percentCrop });
        this.setState({ crop });
      };
      
    renderCropper(src) {
        return <ReactCrop
            src={src}
            crop={this.state.crop}
            onChange={this.onCropChange}
            onComplete={this.onCropComplete}
            onImageLoaded={this.onImageLoaded}
            ruleOfThirds
            minWidth={100}
            minHeight={100}/>
    }

    async makeClientCrop(crop) {
        var croppedImage = await this.getCroppedImg(this.imageRef, crop, this.state.fileName);
        
        this.setState({ croppedImage });
    }

    submit() {
        console.log(this.state.croppedImage);
        
        this.props.onSubmit(this.state.croppedImage);
    }

    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');
    
        ctx.drawImage(
          image,
          crop.x * scaleX,
          crop.y * scaleY,
          crop.width * scaleX,
          crop.height * scaleY,
          0,
          0,
          crop.width,
          crop.height
        );
    
        return new Promise((resolve, reject) => {
          canvas.toBlob(blob => {
            if (!blob) {
              //reject(new Error('Canvas is empty'));
              console.error('Canvas is empty');
              return;
            }
            blob.name = fileName;
            //window.URL.revokeObjectURL(this.fileUrl);
            //this.fileUrl = window.URL.createObjectURL(blob);
                resolve(blob);
        }, "image/jpeg");
    });
    }

    render() {
        let cropper = this.renderCropper(this.state.src);

        return (
            <div class="card">
                <div class="card-body">
                    {cropper}
                    <input type="file" accept="image/*" onChange={this.onSelectFile} />

                </div>
                <div class="card-footer">
                    
                <a onClick={this.submit}>Submit</a>
                </div>
            </div>);
    }
}