import moment from "moment";

export default class DateHelper {
	static ShortDateFormatter(date) {
		var momentDate = moment(date);
		return momentDate.format("DD/MM/YYYY");
	}

	static LongDateFormatter(date) {
		var momentDate = moment(date);
		return momentDate.format("DD MMMM YYYY");
	}

	static MediumDateFormatter(date) {
		var momentDate = moment(date);
		return momentDate.format("DD MMM YYYY");
	}
}
