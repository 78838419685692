import React, { Component } from "react";
import logo from "../../style/img/logo.svg";
import defaultImage from "../../style/img/products/item (1).jpg";
import { withRouter } from "react-router-dom";
import { useHistory } from "react-router-dom";
import FileRepository from "../../repositories/FileRepository";
import Rating from "@material-ui/lab/Rating";
import { Avatar } from "@material-ui/core";
import AvatarGroup from '@material-ui/lab/AvatarGroup';

class CustomerCell extends Component {
  constructor(props) {
    super(props);

    this.state = {
      customer: props.customer
    };

    this.goToJobDetails = this.goToCustomerDetails.bind(this);
  }

  goToCustomerDetails() {
    this.props.history.push("/customer/" + this.state.customer.objectId +"/details");
  }


  render() {
    let customer = this.state.customer;
    return (
      <div className="card m-b-20 jobCell"  >
      <div className="card-header">
      </div>
      <div className="card-body">
          <h4 className="text-center m-t-20">
              {customer.phoneNumber}
          </h4>
          <div className="text-muted text-center m-b-20">
              {customer.email}
          </div>
         
          <div className="text-center p-b-20">
              <button className="btn btn-primary" onClick={this.goToCustomerDetails}>View Customer</button>
          </div>
      </div>
  </div>
    );
  }
}

export default withRouter(CustomerCell);
