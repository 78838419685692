import React, { Component, useState } from "react";
import { DatePicker, TimePicker, DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import SelectionBox from "../../components/Inputs/SelectionBox";
import PartList from "../../components/Parts/PartList";
import logo from "../../style/img/logo.svg";
import { Avatar } from "@material-ui/core";
import PartRepository from "../../repositories/PartRepository";
import PartQuantityList from "../../components/Parts/PartQuantityList";
import PartAmount from "../../models/PartAmount";

export default class JobPartSelection extends Component {
	constructor(props) {
		super(props);
		this.state = { parts: [] };
		if (this.props.tabPane) {
			this.state.tabId = this.props.tabPane.tabId;
		}
		if (this.props.state) {
			this.state = this.props.state;
		}
		this.handlePartSelection = this.handlePartSelection.bind(this);
		this.optionDisabled = this.optionDisabled.bind(this);
	}

	handlePartSelection(e, part) {
		if (part === undefined || part === null) {
			return;
        }
        let partAmountDto = new PartAmount(part);
        let parts = this.state.parts;
		parts.push(partAmountDto);
		this.setState({ parts });

		var currentState = { ...this.state, parts};
		this.props.onUpdated(currentState);
    }
    
    mapParts(part) {
        return new PartAmount(part);
    }

	optionDisabled(option) {
		let parts = this.state.parts;
		return parts.find(x => x.partId === option.objectId) !== undefined;
	}

	optionRenderer(option) {
		return (
			<li className="MuiAutocomplete-option">
				<Avatar variant="square" src={logo} />
				<label>{option.name}</label>
			</li>
		);
	}

	render() {
		return (
			<div className="jobPartSelection align-middle">
				<h3>
					<span className="align-middle">Part Selection</span>
				</h3>
				<SelectionBox
					disableCloseOnSelect
					renderOption={this.optionRenderer}
					noOptionsText="No Parts"
					filter={this.optionDisabled}
					label="Select Part"
					onSelected={this.handlePartSelection}
					searchFunction={PartRepository.GetPartPage}
					mapping={x => x.name}
				/>

				<PartQuantityList parts={this.state.parts}></PartQuantityList>
			</div>
		);
	}
}
