import React, { Component } from "react";
import { connect } from "react-redux";
import actions from "../../actions/actions";
import SearchBox from "../../components/SearchBox/SearchBox";
import PaginatedListComponent from "../../components/Pagination/PaginatedListComponent";
import PartCell from "../../components/Parts/PartCell";
import uuid from "uuid";
import PartRow from "../../components/Parts/PartRow";
import PartRepository from "../../repositories/PartRepository";
import SwitchButton from "../../components/Buttons/SwitchButton";
import PaginationSection from "../../components/Pagination/PaginationSection";
import Button from "../../components/Buttons/Button";

class PartIndex extends Component {
	constructor(props) {
		super(props);
		this.displayNotification = this.displayNotification.bind(this);
		this.state = {
			currentPage: 1,
			searchTerm: "",
			parts: [],
			totalPages: 0,
			activeButton: 1,
			renderMode: "grid",
		};
		this.search = this.search.bind(this);
		this.toggleRenderMode = this.toggleRenderMode.bind(this);
	}

	displayNotification() {
		this.props.dispatch(actions.addNotification("test", "desc"));
	}

	search(searchTerm) {
		this.setState({ searchTerm });
	}

	toggleRenderMode(mode) {
		let activeButton = 0;
		if (mode === "grid") {
			activeButton = 1;
		}
		this.setState({ renderMode: mode, activeButton });
	}

	renderParts(mode, part) {
        console.log(part);
		if (mode == "grid") {
			return <PartCell key={uuid.v4()} part={part} />;
		} else {
			return <PartRow key={uuid.v4()} part={part}></PartRow>;
		}
	}

	render() {
		return (
			<div>
				<div className="bg-dark">
					<div className="row p-t-20 p-b-10">
						<div className="col-md-6 text-center mx-auto text-white">
							<div className="form-dark headerSearchBar">
								<SearchBox responseFunction={this.search} placeHolder="Search Part Catalog"></SearchBox>
								<SwitchButton
									onClick={this.toggleRenderMode}
									activeButton={this.state.activeButton}
									aButton={{
										name: <i className="mdi mdi-view-headline" />,
										value: "list",
									}}
									bButton={{
										name: <i className="mdi mdi-view-grid" />,
										value: "grid",
									}}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-2">
						<div className="row">
							<div className="card m-b-30 partFilter">
								<div className="card-header">
									<h5 className="m-b-0">Filter</h5>
									<p className="m-b-0 text-muted">Filter your parts here</p>
								</div>
								<div className="card-body">
									<div className="m-b-10">
										<label> Slider</label>
									</div>
								</div>
							</div>
						</div>

						<div className="row">
							<div className="col-12">
								<Button className="btn-primary">Create Part</Button>
							</div>
						</div>
					</div>
					<div className="col-10">
						<PaginatedListComponent
							searchTerm={this.state.searchTerm}
							displayMode="grid"
							tableHeaders={["", "Name", "Category", "Sub Category", "Rating", "Stock"]}
							renderer={this.renderParts}
							loadFunction={PartRepository.GetPartPage}
							hasDisplayToggle={false}
							displayMode={this.state.renderMode}
						/>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = function(state) {
	return state.notificationReducer;
};

export default connect(mapStateToProps)(PartIndex);
