import React, { Component } from "react";
import PartRow from "./PartRow";
import PartRepository from "../../repositories/PartRepository";
import uuid from "uuid";

export default class PartList extends Component {
	constructor(props) {
		super(props);

		this.state = { parts: [] };

		if (this.props.parts) {
			this.state.parts = this.props.parts;
		}

		this.partsList = this.partsList.bind(this);
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.parts) {
			this.setState({
				parts: nextProps.parts,
			});
		}
	}

	partsList(partsList) {
		if (!partsList) {
			return [];
		}
		var partsElements = [];
		partsList.forEach(part => {
			partsElements.push(<PartRow key={part.objectId} part={part} isJunction={this.props.isJunction}/>);
		});

		return partsElements;
	}

	render() {
		let partMarkdown = this.partsList(this.state.parts);

		return (
			<div className="table-responsive">
				<table className="table align-td-middle table-card">
					<tbody>{partMarkdown}</tbody>
				</table>
			</div>
		);
	}
}
