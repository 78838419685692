import React, { Component } from "react";
import actions from "../../actions/actions";
import { connect } from "react-redux";

class KanBanTaskCreation extends Component {
	constructor(props) {
		super(props);
        this.save = this.save.bind(this);
        this.onChange = this.onChange.bind(this);
		this.state = {
			title: "",
		};
	}

	onChange(e) {
		this.setState({ title: e.target.value });
	}

	save() {
        this.props.onSubmit(this.state.title);
    }

	render() {
		return (
			<div className="kanban-item-create">
				<div className="card">
					<div className="card-body">
						<textarea className=" form-control kaban-name" placeholder="Enter title here" onChange={this.onChange} value={this.state.title}></textarea>
						<div className="p-t-10 text-right">
							<a href="#" onClick={this.props.onClose} className="btn-sm btn-white m-r-10 js-kaban-cancel">
								cancel
							</a>
							<a href="#" onClick={this.save} className="btn-sm btn-primary js-kaban-save">
								save
							</a>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default connect()(KanBanTaskCreation);
