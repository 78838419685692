import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Rating from "@material-ui/lab/Rating";
import defaultImage from "../../style/img/logo.svg";
import FileRepository from "../../repositories/FileRepository";
import PartRepository from "../../repositories/PartRepository";

class PartRow extends Component {
	constructor(props) {
		super(props);

		this.goToPartDetail = this.goToPartDetail.bind(this);
	}

	async GetThumbnailImage() {
		this.setState({ thumbnail: defaultImage });
		if (!this.props.part.images) {
			return defaultImage;
		}

		var firstImage = this.props.part.images[0];
		if (!firstImage)
			return defaultImage;
		//var thumbnail = await FileRepository.GetThumbnailFromStorage(firstImage.location);
		//if(thumbnail == false)
		//    return defaultImage;

		//console.log(thumbnail);
	}

	async GetPartDetails() {
		if (!this.props.part && this.props.isJunction === undefined) {
			console.log("Using existing part");
			this.setState({ part: this.props.part })
			return;
		}
		console.log("Getting part details");
		var partId;

		if (this.props.isJunction) {
			partId = this.props.part.partId;
		} else {
			partId = this.props.partId;
		}

		console.log(partId);
		if (!partId) {
			return null;
		}

		var fetchedPart = await PartRepository.GetPartDetails(partId);
		console.log(fetchedPart);
		if (fetchedPart.status !== undefined)
			return ;


		if (this.props.isJunction) {
			fetchedPart = {...fetchedPart, ...this.props.part};
			console.log(fetchedPart)
		}
		this.setState({ part: fetchedPart })

	}

	async componentWillMount() {

		var imageTask = this.GetThumbnailImage();
		var detailsTask = this.GetPartDetails();

		await Promise.all([imageTask, detailsTask]);
	}

	async GetStock() { }

	goToPartDetail() {
		this.props.history.push("/part/details/" + this.state.part.objectId);
	}

	render() {
		let part = this.state.part ? this.state.part : {};
		if (this.props.isJunction) {
			return (
				<tr className="partRow">
					<td>
						<div className="avatar-xl">
							<img src={this.state.thumbnail} className="avatar-img " alt="" />
						</div>
					</td>
					<td>{part.name}</td>
					<td>{part.category}</td>
					<td>{part.subCategory}</td>
					<td>{part.price}</td>
					<td>{part.amount}</td>
				</tr>)
		}
		return (
			<tr className="partRow">
				<td>
					<div className="avatar-xl">
						<img src={this.state.thumbnail} className="avatar-img " alt="" />
					</div>
				</td>
				<td>{part.name}</td>
				<td>{part.category}</td>
				<td>{part.subCategory}</td>
				<td>
					<Rating></Rating>
				</td>
				<td>{part.stockAmount}/50</td>
			</tr>
		);
	}
}

export default withRouter(PartRow);
