import React, { Component } from "react";
import Wizard from "../../components/Wizard/Wizard";
import WizardPane from "../../models/WizardPane";
import JobCreation from "../Jobs/JobCreation";
import ImageUploader from "../../components/Images/ImageUploader";

import Dropzone from "react-dropzone";
import JobCustomerSelection from "../Jobs/JobCustomerSelection";
import JobPartSelection from "../Jobs/JobPartSelection";
import JobSubmit from "../Jobs/JobSubmit";
import Job from "../../models/Job";
import CustomerRepository from "../../repositories/CustomerRepository";
import JobRepository from "../../repositories/JobRepository";
import { connect } from "react-redux";
import actions from "../../actions/actions";
class WizardTestPage extends Component {
	constructor(props) {
		super(props);
		this.state = { tabStates: {}, tabs: [], validTabs: [] };

		let tabs = this.state.tabs;
		tabs.push(new WizardPane("Summary*", true, "jobSummary", 0));
		tabs.push(new WizardPane("Customer*", true, "jobCustomerSelection", 1));
		tabs.push(new WizardPane("Files", false, "jobFileSelection", 2));
		tabs.push(new WizardPane("Parts", false, "jobPartsSelection", 3));
		tabs.push(new WizardPane("Confirm", true, "jobConfirm", 4));
		this.onUpdated = this.onUpdated.bind(this);
		this.onValid = this.onValid.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
	}

	onUpdated(state) {
		let localState = this.state.tabStates;
		localState[state.tabId] = state;

		this.setState({ tabStates: localState });
	}

	onValid(tabId, isValid) {
		this.state.validTabs[tabId] = isValid;
	}

	getTabPane(id) {
		for (let i = 0; i < this.state.tabs.length; i++) {
			const tab = this.state.tabs[i];
			if (tab.tabId == id) {
				return tab;
			}
		}
	}

	onSubmit() {
		console.log(this.state.tabStates);
		let tabStates = this.state.tabStates;
		let jobSummary = tabStates.jobSummary;
		let jobPartsSelection = tabStates.jobPartsSelection;
		let jobCustomerSelection = tabStates.jobCustomerSelection;

		if (jobSummary === undefined) {
			this.props.dispatch(actions.addWarningNotification("Error creating the job", "A job requires a title"));
			return false;
		}

		if(jobCustomerSelection === undefined) {
			this.props.dispatch(actions.addWarningNotification("Error creating the job", "A job requires a customer"));

			return false;
		}

		if(jobSummary.isValid === false) {			
			this.props.dispatch(actions.addWarningNotification("Error creating the job", "The job state is not valid"));
			return false;
		}
        var job = new Job(jobSummary.title,
             jobSummary.description,
             "",
             jobSummary.startDate,
             jobSummary.endDate, 
             jobCustomerSelection.selectedCustomer.objectId);

        if(jobPartsSelection){
            if(jobPartsSelection.parts){

            }
        }

        JobRepository.CreateJob(job).then((result) => {

			console.log(result);
			if(result === false) {
				this.props.dispatch(actions.addErrorNotification("Error creating the job", "We've recieved an unknown error"));
			return;
			}
			this.props.dispatch(actions.addSuccessNotification("Successfully created the job", "We've successfully created: " + result.name));

        })
	}

	render() {
		let dropZone = (
			<form class="dropzone" action="/Part">
				<div class="dz-message">
					<h1 class="display-4">
						<i class=" mdi mdi-progress-upload" />
					</h1>
					Drop files here or click to upload.
					<br />
					<span class="note needsclick">
						(This is just a demo dropzone. Selected files are <strong>not</strong> actually uploaded.)
					</span>
					<div class="p-t-5">
						<a href="#" class="btn btn-lg btn-primary">
							Upload File
						</a>
					</div>
				</div>
			</form>
		);

		let tabStates = this.state.tabStates;
		let tabContents = [];

		tabContents["jobSummary"] = <JobCreation state={tabStates["jobSummary"]} onUpdated={this.onUpdated} onValid={this.onValid} tabPane={this.getTabPane("jobSummary")} />;

		tabContents["jobCustomerSelection"] = (
			<JobCustomerSelection searchLocation="" state={tabStates["jobCustomerSelection"]} tabPane={this.getTabPane("jobCustomerSelection")} onUpdated={this.onUpdated} onValid={this.onValid} />
		);

		tabContents["jobFileSelection"] = dropZone;

		tabContents["jobPartsSelection"] = <JobPartSelection onUpdated={this.onUpdated} tabPane={this.getTabPane("jobPartsSelection")} state={tabStates["jobPartsSelection"]} />;

		tabContents["jobConfirm"] = <JobSubmit tabs={this.state.tabs} validTabs={this.state.validTabs} onSubmit={this.onSubmit} />;

		return <Wizard tabs={{ tabDefinitions: this.state.tabs, contents: tabContents }} firstTab="jobSummary" />;
	}
}


export default connect()(WizardTestPage);