import React, { Component } from "react";

export default class WizardTab extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		let className = "tab-pane p-t-20 p-b-20 active";
		if (this.props.className) {
			className += " " + this.props.className;
		}

		return (
			<div className={className} id={this.props.key}>
				{this.props.children}
			</div>
		);
	}
}
