import React, { Component } from "react";

export default class JobTaskResult extends React.Component {
    render() {
        let result = this.props.result || {};
        return (
            <div class="list-group-item d-flex  align-items-center searchResult">
                <div class="m-r-20 resultLeftPanel">
                    <div className="itemType">Job Task</div>
                    <div class="avatar avatar-sm ">
                        <div className="jobTaskSearchColour" style={{ backgroundColor: result.timelineColor }}></div>
                    </div>
                </div>
                <div class="jobTaskResultBody">
                    <div className="text-content">
                        <div class="name">{result.name}</div>
                        <div class="text-muted">{result.state}</div>
                    </div>
                </div>
            </div>
        );
    }
}