import React, { Component } from "react";
import Group from "./Group";
import TaskRepository from "../../repositories/TaskRepository";
import TaskEditSideBar from "./TaskEditSideBar";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Avatar, Tooltip } from "@material-ui/core";

export default class Board extends Component {
	constructor(props) {
		super(props);
		this.state = { job: this.props.job, states: [], tasks: {}, sideBarShowing: false };
		this.renderGroups = this.renderGroups.bind(this);
		this.getTasks = this.getTasks.bind(this);
		this.move = this.move.bind(this);
		this.onDragEnd = this.onDragEnd.bind(this);
	}

	async componentWillMount() {
		var states = await this.getStates();
		if (states == false) {
			return;
		}

		for (let i = 0; i < states.length; i++) {
			const state = states[i];
			await this.getTasks(state.name);
		}

		this.setState({ states });
	}

	async getStates() {
		console.log(this.props.jobId);
		return await TaskRepository.GetStates(this.props.jobId);
	}

	renderGroups() {
		let groupStates = [];
		let states = this.state.states;
		states.forEach(element => {
			let tasks = this.state.tasks[element.name];
			if (tasks === undefined) {
				return;
			}
			groupStates.push(
				<Droppable key={element.name} droppableId={element.name} index={element.index}>
					{(provided, snapshot) => <Group status={element.name} key={element.name} tasks={tasks} {...provided.droppableProps} innerRef={provided.innerRef} />}
				</Droppable>
			);
		});
		return groupStates;
	}

	move(result) {
		const { destination, source } = result;
		console.log(result);
		let tasks = this.state.tasks;

		let destinationArray = tasks[destination.droppableId].listData;
		let sourceArray = tasks[source.droppableId].listData;
		const sourceClone = Array.from(sourceArray);
		const destClone = Array.from(destinationArray);

		const [removed] = sourceClone.splice(source.index, 1);
		destClone.splice(destination.index, 0, removed);

		tasks[destination.droppableId].listData = destClone;
		tasks[source.droppableId].listData = sourceClone;

		this.setState({ tasks });
	}

	async getTasks(state) {
		var tasksPage = await TaskRepository.GetTaskByState(this.props.jobId, state, 1);

		if (tasksPage === false) {
			return;
		}

		let tasks = this.state.tasks;
		tasks[state] = tasksPage;
		this.setState({ tasks });
	}

	async onDragEnd(result) {
		// dropped outside the list
		if (!result.destination) {
			return;
		}

		if (result.destination.droppableId === result.source.droppableId) {
			return;
		}
		this.move(result);
		await TaskRepository.UpdateTaskByState(result.draggableId, result.destination.droppableId);
	}

	render() {
		let groups = this.renderGroups();
		return (
			<>
				<DragDropContext onDragEnd={this.onDragEnd}>
					<div className="kanban-workspace row ui-sortable">{groups}</div>
				</DragDropContext>
			</>
		);
	}
}
