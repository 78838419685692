import React, { Component } from "react";
import logo from "../../style/img/logo.svg";
import defaultImage from "../../style/img/products/item (1).jpg";
import { withRouter } from "react-router-dom";
import { useHistory } from "react-router-dom";
import FileRepository from "../../repositories/FileRepository";
import Rating from "@material-ui/lab/Rating";
import { Avatar } from "@material-ui/core";
import AvatarGroup from '@material-ui/lab/AvatarGroup';

class JobCell extends Component {
  constructor(props) {
    super(props);

    this.state = {
      job: props.job
    };

    this.goToJobDetails = this.goToJobDetails.bind(this);
  }

  goToJobDetails() {
    this.props.history.push("/job/" + this.state.job.objectId + "/details");
  }

  getProjectType(type) {
    if (typeof type != 'number') {
      return type;
    }
    switch (type) {
      case 0:
        return "Quoted";
      case 1:
        return "New";
      case 2:
        return "In-Progress";
      case 3:
        return "Completed";
      case 4:
        return "Staged";
      default:
        break;
    }
  }

  render() {
    let job = this.state.job;
    console.log(job);
    return (
      <div className="card m-b-30 jobCell"  >
        <div className="card-header">
          <div className="card-title"><i className="mdi mdi-18px text-info mdi-circle"></i> {this.getProjectType(job.state)}
          </div>

          <div className="card-controls">

            <a href="#" className="js-card-refresh icon"> </a>
            <div className="dropdown">
              <a href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i className="icon mdi  mdi-dots-vertical"></i> </a>

              <div className="dropdown-menu dropdown-menu-right">
                <button className="dropdown-item" type="button">Action</button>
                <button className="dropdown-item" type="button">Another action</button>
                <button className="dropdown-item" type="button">Something else here</button>
              </div>
            </div>
          </div>

        </div>
        <div className="card-body">
          <h4 className="text-center m-t-20">
            {job.name}
          </h4>
          <div className="text-muted text-center m-b-20">
            5 Members
          </div>
          <div className="text-center m-b-30">
            <AvatarGroup className="justify-center">
              <Avatar>A</Avatar>
              <Avatar>B</Avatar>
              <Avatar>C</Avatar>
              <Avatar>+2</Avatar>
            </AvatarGroup>
          </div>


          <div className="text-center p-b-20">
            <button className="btn btn-primary" onClick={this.goToJobDetails}>View Job</button>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(JobCell);
