import React from "react";

/**
 * The default sitesolve button components
 */
export default class SwitchButton extends React.Component {
  constructor(props) {
    super(props);

    let aButton = props.aButton;
    aButton.index = 0;
    let bButton = props.bButton;
    bButton.index = 1;

    this.state = {
      className: props.className,
      aButton,
      bButton,
      activeButton: 0,
      disabled: props.disabled
    };
    if (this.props.activeButton) {
      this.state.activeButton = this.props.activeButton;
    }

    this.buttonToggled = this.buttonToggled.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.disabled) {
      this.setState({ disabled: nextProps.disabled });
    }
  }

  buttonToggled(button) {
    this.props.onClick(button.value);

    this.setState({ activeButton: button.index });
  }

  getButtonClass(buttonNum) {
    let buttonClass = "btn-dark";
    if (this.state.className) {
      buttonClass = this.state.className;
    }

    if (this.state.activeButton === buttonNum) {
      buttonClass += " active";
    }

    var classString = "btn " + buttonClass;
    return classString;
  }

  render() {
    var groupString = "btn-group btn-group-toggle";
    if (this.state.disabled === true) {
      groupString += " disabled";
    }

    let aButtonClass = this.getButtonClass(0);
    let bButtonClass = this.getButtonClass(1);

    return (
      <div className={groupString} role="group">
        <button
          type="button"
          className={aButtonClass}
          onClick={() => this.buttonToggled(this.state.aButton)}
        >
          {this.state.aButton.name}
        </button>
        <button
          type="button"
          className={bButtonClass}
          onClick={() => this.buttonToggled(this.state.bButton)}
        >
          {this.state.bButton.name}
        </button>
      </div>
    );
  }
}
