import React, { Component } from "react";
import logo from "../../style/img/logo.svg";
import TimelineItem from "./TimelineItem";

export default class Timeline extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: props.data,
		};
	}

	render() {
		return (
			<div className="card">
				<div className="card-header">
					<div className="card-title">Timeline</div>

					<div className="card-controls">
						<select className="custom-select">
							<option value="">Everything</option>
							<option value="">Charges</option>
							<option value="">Upgrades</option>
						</select>
					</div>
				</div>
				<div className="card-body">
					<div className="timeline">{this.props.children}</div>
				</div>
			</div>
		);
	}
}
