
import React, { Component } from 'react';
import { connect } from 'react-redux'
import UserSummary from '../../components/User/UserSummary';
import UserHeader from '../../components/User/UserHeader';
import UserNotes from '../../components/User/UserNotes';
import Timeline from '../../components/Timeline/Timeline';
class Profile extends Component {
    constructor(props) {
        super(props);
        this.user = {
            fullName: "Jack Skitt",
            jobTitle: "Software Architect",
            summary: "Summary",
            dob: "1994-11-16T00:00:00+00:00"


        }
    }

    render() {
        return (
            <section className="admin-content">
                <div className="bg-dark m-b-30">
                    <div className="container">
                        <UserHeader userProfile={this.user}/>
                    </div>
                    <div className="container pull-up">
                        <div className="row">
                            <div className="col-lg-4 order-1 order-lg-0 m-b-30">
                                <UserSummary userProfile={this.user}/>
                                <UserNotes />
                            </div>
                            <div className="col-lg-8 m-b-30">
                                <Timeline />
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        );
    }
}
const mapStateToProps = function (state) {
    return state.notificationReducer;
}

export default connect(
    mapStateToProps,
)(Profile)