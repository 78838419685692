import React, { Component } from "react";
import { Drawer, TextField, Divider, Paper, Tabs, Tab } from "@material-ui/core";
import { connect } from "react-redux";
import actions from "../../actions/actions";
import DateHelper from "../../helpers/DateHelper";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import HistoryItem from "../History/HistoryItem";
import UserRow from "../User/UserRow";
import TaskRepository from "../../repositories/TaskRepository";

class TaskEditSideBar extends Component {
	constructor(props) {
		super(props);
		this.state = { showing: false, task: {}, value: 0 };
		this.closeState = this.closeState.bind(this);
		this.handleChange = this.handleChange.bind(this);
        this.updateState = this.updateState.bind(this);
        this.save = this.save.bind(this);
		this.updateState(props);
	}

	closeState() {
		this.props.dispatch(actions.taskEditClosed());
	}

	componentWillReceiveProps(nextProps) {
		this.updateState(nextProps);
	}

	updateState(props) {
		this.setState({ showing: props.taskShowing, task: props.selectedTask });
	}

	handleChange(e, newValue) {
		this.setState({ value: newValue });
    }
    
    handleTaskChange(date, type){
        var task = this.state.task;
        task[type] = date;
        this.setState({task: task});
    }
    removeUser(userId) {

    }

    async save() {
        var result = await TaskRepository.UpdateTask(this.state.task);
        console.log(result);
        if(result !== false) {
            this.closeState();
        }
    }

	render() {
		let value = this.state.value;
		let task = this.state.task || {};

		return (
			<Drawer anchor="right" open={this.state.showing}>
				<div className="modal fade modal-slide-right show" id="kanbanItemModal" role="dialog" aria-modal="true" style={{ display: "block" }}>
					<div className="modal-dialog" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<div className="row">
									<div className="col-md-12">
										<h5 className="m-b-0 js-modal-title"> {task.name} </h5>
										<div className="text-muted  ">
											in <span className="js-copy-board">{task.state}</span>
										</div>
									</div>
								</div>
								<button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.closeState}>
									<span aria-hidden="true">×</span>
								</button>
							</div>
							<div className="modal-body overflow-auto">
								<div className="row">
									<div className="col-lg-12 col-md-12">
										<TextField variant="outlined" margin="normal" size="small" label="Task Name" onChange={(value) => this.handleTaskChange(value.target.value, "name")} fullWidth defaultValue={task.name}></TextField>
									</div>
								</div>
								<div className="row">
									<div className="col-lg-12 col-md-12">
										<TextField onChange={(value) => this.handleTaskChange(value.target.value, "description")} variant="outlined" size="small" label="Task Description" multiline rows="4" fullWidth defaultValue={task.description}></TextField>
									</div>
								</div>

								<MuiPickersUtilsProvider utils={MomentUtils}>
									<div className="row">
										<div className="col-lg-12 col-md-12">
											<div className="row">
												<div className="col-lg-6 col-md-6">Start Date</div>
												<div className="col-lg-6 col-md-6">End Date</div>
											</div>
											<div className="row">
												<div className="col-lg-6 col-md-6">
													<DatePicker
                                                        format="DD/MMM/YYYY"
                                                        showTodayButton
                                                        onChange={(date) => this.handleTaskChange(date, "startTime")}
														value={task.startTime}
													/>
												</div>
												<div className="col-lg-6 col-md-6">
													<DatePicker
                                                        format="DD/MMM/YYYY"
                                                        showTodayButton
                                                        onChange={(date) => this.handleTaskChange(date, "endTime")}
														value={task.endTime}
														minDate={task.startTime}
														minDateMessage={"End date cannot be before the start date"}
													/>
												</div>
											</div>
										</div>
									</div>
								</MuiPickersUtilsProvider>
								<Divider variant="fullWidth" style={{ marginTop: 15, marginBottom: 15, width: "100%" }} />

								<Tabs value={value} indicatorColor="primary" textColor="primary" onChange={this.handleChange} variant="scrollable" scrollButtons="auto">
									<Tab label="Users" />
									<Tab label="Sheets" />
									<Tab label="Files" />
									<Tab label="Activity" />
								</Tabs>
								<TabPanel index={0} value={value}>
									<div className="list-group list-group-flush row">
                                        <UserRow user={{firstName: "Jack", lastName: "Skitt", jobTitle:"Visual Designer"}} OnRemove={this.removeUser}/>
									    <UserRow user={{firstName: "Jack", lastName: "Skitt", jobTitle:"Visual Designer"}} OnRemove={this.removeUser}/>
									</div>
								</TabPanel>
								<TabPanel index={1} value={value}>
									<div className="list-group list-group-flush row">
										<HistoryItem history={{ userName: "Jack Skitt", operation: "Submitted sheet" }}></HistoryItem>
										<HistoryItem history={{ userName: "Robert Obrien", operation: "Assigned dates for this task" }}></HistoryItem>
										<HistoryItem history={{ userName: "Howard Franklin", operation: "Created task." }}></HistoryItem>
									</div>
								</TabPanel>
							</div>
							<div className="modal-footer justify-content-start">
								<button type="button" data-dismiss="modal" onClick={this.save} className="btn btn-primary">
									Save changes
								</button>
								<a href="#!" className="btn btn-danger">
									<i className="mdi mdi-delete"></i> Delete
								</a>
								<button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={this.closeState}>
									Close
								</button>
							</div>
						</div>
					</div>
				</div>
			</Drawer>
		);
	}
}

const mapStateToProps = function(state) {
	return state.kanbanReducer;
};

export default connect(mapStateToProps)(TaskEditSideBar);

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div component="div" role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
			{children}
		</div>
	);
}
