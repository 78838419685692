import React, { Component } from 'react';
import logo from '../../style/img/logo.svg'

export default class Badge extends Component {
    constructor(props) {
        super(props);

        this.state = {
            text: props.text,
            colour: props.colour
        };
    }

    render() {
        return (
            <a href="$" className={"badge-soft-" + this.state.colour + "badge"}>{this.state.text}</a>);
    }
}