import React, { Component } from "react";

export default class QuoteResult extends React.Component {
    render() {
        let result = this.props.result || {};
        return (
            <div class="list-group-item d-flex  align-items-center searchResult">
                <div class="m-r-20 resultLeftPanel">
                    <div className="itemType">Quote</div>
                    <div class="avatar avatar-sm ">
                        <div class="avatar-title bg-primary rounded"><i class="mdi mdi-24px mdi-code-braces"></i></div>
                    </div>
                </div>
                <div class="jobTaskResultBody">
                    <div className="text-content">
                        <div class="name">{result.name}</div>
                        <div class="text-muted">{result.jobName}</div>
                    </div>
                </div>
            </div>
        );
    }
}