import React, { Component } from "react";
import logo from "../../style/img/logo.svg";
import Badge from "@material-ui/core/Badge";
import NotificationsIcon from '@material-ui/icons/Notifications';

export default class NotificationButton extends Component {
  constructor(props) {
    super(props);
  }

  render() {

    return (
      <Badge color="secondary" badgeContent={5}>
        <NotificationsIcon />
      </Badge>
    );
  }
}
