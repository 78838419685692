import React from "react";
import PaginationButton from "./PaginationButton";
import uuid from "uuid";

/**
 * The default sitesolve button components
 */
export default class PaginationSection extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			totalPages: props.totalPages,
			maxButtonCount: 3,
			currentPage: props.currentPage,
			selectionFunction: props.selectionFunction,
			hasFirstAndLast: true,
		};

		if (props.maxButtonCount) {
			this.state.maxButtonCount = props.maxButtonCount;
		}
		if (props.hasFirstAndLast) {
			this.state.hasFirstAndLast = props.hasFirstAndLast;
		}
		this.onButtonSelected = this.onButtonSelected.bind(this);
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.currentPage) {
			this.setState({ currentPage: nextProps.currentPage });
		}
	}

	onButtonSelected(index) {
		var hasNextPage = true;
		var hasPrevPage = true;
		if (index >= this.state.totalPages) {
			index = this.state.totalPages;
			hasNextPage = false;
			this.setState({ hasNextPage });
		}

		if (index <= 1) {
			index = 1;
			hasPrevPage = false;
			this.setState({ hasPrevPage });
		}

		this.state.selectionFunction(index);
		this.setState({ hasNextPage, hasPrevPage, currentPage: index });
	}

	getPageButtons(start, count) {
		var buttons = [];
		for (let i = 0; i < count; i++) {
			let numberToSet = start + i;
			let isActive = numberToSet === this.state.currentPage;
			buttons.push(<PaginationButton key={uuid.v4()} onClick={this.onButtonSelected} number={start + i} isActive={isActive} />);
		}
		return buttons;
	}

	getButtons() {
		if (this.state.totalPages < 0) {
			return <></>;
		}

		if (this.state.totalPages == 1) {
			return this.getPageButtons(1, 1);
		}

		if (this.state.currentPage < 2 && this.state.totalPages > 2) {
			return this.getPageButtons(1, 2);
		}

		if (this.state.currentPage > this.state.totalPages - 1 && this.state.totalPages > 2) {
			return this.getPageButtons(this.state.totalPages - 1, 2);
		}

		return this.getPageButtons(this.state.currentPage - 1, 3);
	}

	render() {
		let buttons = this.getButtons();

		let firstButton = <></>;
		let lastButton = <></>;

		if (this.state.hasFirstAndLast === true) {
			firstButton = (
				<PaginationButton disabled={this.state.hasPrevPage === false} onClick={this.onButtonSelected} number={1}>
					First
				</PaginationButton>
			);
			lastButton = (
				<PaginationButton disabled={this.state.hasNextPage === false} onClick={this.onButtonSelected} number={this.state.totalPages}>
					Last
				</PaginationButton>
			);
		}

		return (
			<div className="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
				<div className="btn-group" role="group" aria-label="Third group">
					{firstButton}
					<PaginationButton onClick={this.onButtonSelected} disabled={this.state.hasPrevPage === false} number={this.state.currentPage - 1}>
						Prev
					</PaginationButton>
					{buttons}
					<PaginationButton onClick={this.onButtonSelected} disabled={this.state.hasNextPage === false} number={this.state.currentPage + 1}>
						Next
					</PaginationButton>
					{lastButton}
				</div>
			</div>
		);
	}
}
