import React, { Component } from "react";
import PartQuantityRow from "./PartQuantityRow";
import PartRepository from "../../repositories/PartRepository";
import uuid from "uuid";

export default class PartQuantityList extends Component {
	constructor(props) {
		super(props);

		this.state = { parts: [] };

		if (this.props.parts) {
			this.state.parts = this.props.parts;
		}

        this.partsList = this.partsList.bind(this);
        this.updateParts = this.updateParts.bind(this);
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.parts) {
			this.setState({
				parts: nextProps.parts,
			});
		}
	}

	updateParts(part) {
		let parts = this.state.parts;

		var index = parts.findIndex(x => x.partId == part.partId);

		parts[index] = part;
	}

	partsList(partsList) {
		if (!partsList) {
			return [];
		}
		var partsElements = [];
		partsList.forEach(part => {
			partsElements.push(<PartQuantityRow key={part.objectId} updatedRow={this.updateParts} part={part} />);
		});

		return partsElements;
	}

	render() {
		let partMarkdown = this.partsList(this.state.parts);
		if (this.state.parts.length === 0) {
			return <></>;
		}
		return (
			<div className="table-responsive">
				<table className="table align-td-middle table-card">
					<thead>
						<tr>
							<th></th>
							<th>Name</th>
							<th>Quantity</th>
							<th>Price</th>
						</tr>
					</thead>
					<tbody>{partMarkdown}</tbody>
				</table>
			</div>
		);
	}
}
