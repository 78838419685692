import React, { Component } from "react";
import actions from "../actions/actions";
import { connect } from "react-redux";
import Chart from "react-apexcharts";

class Home extends Component {
	constructor(props) {
		super(props);
		this.displayNotification = this.displayNotification.bind(this);

		this.state = { chart: {} };
	}

	displayNotification() {
		this.props.dispatch(actions.addNotification("test", "desc"));
	}

	render() {
        var options03 = {
            colors: ["#07427a", "#87bbfd"],
            chart: {
                sparkline: {
                    enabled: true
                },
                height: 150,
                type: 'area',
                toolbar: {
                    show: false
                },
            },
            dataLabels: {
                enabled: false
            },
            fill: {
                opacity: 1,
                type: 'solid'
            },
            stroke: {
                curve: 'straight'
            },
            series: [{
                name: 'Quotes',
                data: [51, 42, 45, 31, 40, 28, 35]
            }],

            xaxis: {
                type: 'datetime',
                categories: ["2018-09-19T00:00:00", "2018-09-19T01:30:00", "2018-09-19T02:30:00", "2018-09-19T03:30:00", "2018-09-19T04:30:00", "2018-09-19T05:30:00", "2018-09-19T06:30:00"],
            },
            tooltip: {
                x: {
                    format: 'dd/MM/yy HH:mm'
                },
            }
        }
        var options01 = {
            colors: ["#07427a", "#87bbfd"],
            chart: {
                height:"400",
                type: 'bar',
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    endingShape: 'rounded',
                    columnWidth: '55%',
                },
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'straight'
            },
            series: [{
                name: 'Scheduled',
                data: [44, 55, 57, 56, 61, 58, 63, 60, 66]
            }, {
                name: 'Completed',
                data: [76, 85, 101, 98, 87, 105, 91, 114, 94]
            }],
            xaxis: {
                categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct'],
            },

            fill: {
                opacity: 1,
                type: 'solid'
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return val + " jobs "
                    }
                }
            }
        }

        var options02 = {
            colors: ["#ffc100", "#f5a623"],
            chart: {
                height:"400",
                type: 'bar',
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '60%',
                },
            },
            dataLabels: {
                enabled: false
            },
            legend: {
                labels: {
                    colors: "#fff",
                },
            },
            grid: {
                borderColor: 'rgba(255,225,255,0.2)',
                strokeDashArray: '3',

            },
            stroke: {
                show: true,
                width: 2,
                colors: ['transparent']
            },
            series: [{
                name: 'Last Month',
                data: [44, 55, 42, 25, 58, 58, 63, 60, 33]
            }, {
                name: 'This Month',
                data: [76, 20, 40, 98, 87, 50, 91, 98, 60]
            }],
            xaxis: {
                categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct'],
            },

            fill: {
                opacity: 1

            },
            // legend: {
            //     floating: true
            // },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return "$ " + val + " thousands"
                    }
                }
            }
        }

		return (
			<div class="container py-5">
				<div class="row ">
					<div class="col-lg-4  m-b-30 ">
						<div class="card m-b-30 full-height">
							<img src="/images/patterns/header.png" class="rounded-top" width="100%" alt="header" />
							<div class="card-body  bg-gray-900 rounded-bottom ">
								<div class="pull-up-sm">
									<div class="avatar avatar-lg">
										<div class="avatar-title rounded-circle mdi mdi-heart bg-primary"></div>
									</div>
								</div>
								<h1 class="text-white pt-4 fw-300">
									<span class="js-greeting">Good Morning</span>,
								</h1>
								<p class="opacity-75 text-white">
									You have 3 jobs to complete today in 3 tasks.
								</p>
								<div>
									<a href="#!" class="btn btn-success" onClick={this.displayNotification}>
										View Tasks
									</a>
								</div>
							</div>
						</div>
					</div>
					<div class="col-lg-8 col-md-12  ">
						<div class="card m-b-30">
							<div class="card-header">
								<h5 class="card-title m-b-0">Jobs completed</h5>

								<div class="card-controls">
									<a href="#" class="js-card-refresh icon"></a>
									<div class="dropdown">
										<a href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
											{" "}
											<i class="icon mdi  mdi-dots-vertical"></i>{" "}
										</a>

										<div class="dropdown-menu dropdown-menu-right">
											<button class="dropdown-item" type="button">
												Action
											</button>
											<button class="dropdown-item" type="button">
												Another action
											</button>
											<button class="dropdown-item" type="button">
												Something else here
											</button>
										</div>
									</div>
								</div>
							</div>
							<div class="card-body  ">                                
								<Chart options={options01} series={options01.series} type="area" height={options01.chart.height}/>
							</div>
						</div>
					</div>
				</div>
				<div class="row ">
					<div class="col-lg-8 col-md-12  ">
						<div class="card bg-gray-900 m-b-30">
							<div class="card-header text-white">
								<h5 class="card-title m-b-0">Revenue</h5>

								<div class="card-controls">
									<a href="#" class="js-card-refresh icon"></a>
									<div class="dropdown">
										<a href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
											{" "}
											<i class="icon mdi  mdi-dots-vertical"></i>{" "}
										</a>

										<div class="dropdown-menu dropdown-menu-right">
											<button class="dropdown-item" type="button">
												Action
											</button>
											<button class="dropdown-item" type="button">
												Another action
											</button>
											<button class="dropdown-item" type="button">
												Something else here
											</button>
										</div>
									</div>
								</div>
							</div>
							<div class="card-body bg-gray-900 rounded">
								<div id="chart-02" class="invert-colors">
                                    
								<Chart options={options02} series={options02.series} type="bar" height={options02.chart.height}/>
                                </div>
							</div>
						</div>
					</div>
					<div class="col-lg-4  m-b-30 ">
						<div class="card m-b-30">
							<div class="card-header">
								<h5 class="card-title m-b-0">Quotes this month</h5>

								<div class="card-controls">
									<a href="#" class="js-card-refresh icon"></a>
									<div class="dropdown">
										<a href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
											{" "}
											<i class="icon mdi  mdi-dots-vertical"></i>{" "}
										</a>

										<div class="dropdown-menu dropdown-menu-right">
											<button class="dropdown-item" type="button">
												Action
											</button>
											<button class="dropdown-item" type="button">
												Another action
											</button>
											<button class="dropdown-item" type="button">
												Something else here
											</button>
										</div>
									</div>
								</div>
							</div>

							<div class="card-body">
								<div class="row p-t-15 ">
									<div class="col-sm-6 my-auto ">
										<h5 class="m-0">
											Total Quotes <a href="#" class="mdi mdi-information text-muted"></a>
										</h5>
									</div>
								</div>
								<div class="row p-t-10">
									<div class="col-sm-6 my-auto ">
										<h3 class="">103</h3>
									</div>
									<div class="col-sm-6 my-auto  text-right ">
										<h3 class="text-success">
											<i class="mdi mdi-arrow-up"></i> 2.6%
										</h3>
									</div>
								</div>

								<ul class="list-group m-t-10">
									<li class="list-group-item list-group-item d-flex justify-content-between align-items-center">
										<span>
											<span class="d-block">
												{" "}
												<i class="mdi "></i> Completed
											</span>
										</span>
										<span>
											<span class="d-block"> 10 </span>
											<span class="text-success d-block"> +15% </span>
										</span>
									</li>
									<li class="list-group-item list-group-item d-flex justify-content-between align-items-center">
										<span>
											<span class="d-block">
												{" "}
												<i class="mdi "></i> In-Progress
											</span>
										</span>
										<span>
											<span class="d-block"> 10 </span>
											<span class="text-success d-block"> +36% </span>
										</span>
									</li>
									<li class="list-group-item list-group-item d-flex justify-content-between align-items-center">
										<span>
											<span class="d-block">
												{" "}
												<i class="mdi "></i> New
											</span>
										</span>
										<span>
											<span class="d-block"> 102 </span>
											<span class="text-danger d-block"> -5% </span>
										</span>
									</li>
								</ul>
							</div>

							<div class="card-img">
                                
								<Chart options={options03} series={options03.series} type="area" height={options03.chart.height}/>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = function(state) {
	return state.notificationReducer;
};

export default connect(mapStateToProps)(Home);
