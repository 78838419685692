import React, { Component } from "react";
import { connect } from "react-redux";
import WizardTab from "./WizardTab";
import WizardTabButton from "./WizardTabButton";
import actions from "../../actions/actions";
import logo from "../../style/img/logo.svg";

class Wizard extends Component {
	constructor(props) {
		super(props);

		let firstTabId = "";

		if (this.props.tabs.tabDefinitions) {
			firstTabId = this.props.tabs.tabDefinitions[0].tabId;
		}

		this.state = {
			activeTabId: firstTabId,
			tabs: this.props.tabs,
		};
		this.tabChanged = this.tabChanged.bind(this);
	}

	renderButtons(panes) {
		if (panes === undefined) {
			return [];
		}

		let tabs = [];
		for (let i = 0; i < panes.length; i++) {
			let currentPane = panes[i];
			let isActive = currentPane.tabId === this.state.activeTabId;
			tabs.push(
				<WizardTabButton key={"tabButton_" + currentPane.tabId} onClick={this.tabChanged} tabId={currentPane.tabId} active={isActive}>
					{currentPane.title}
				</WizardTabButton>
			);
		}
		return tabs;
	}

	renderContents(panes) {
		if (panes === undefined) {
			return [];
		}

		let tabs = [];
		panes.forEach(tab => {
			let isActive = tab.tabId === this.state.activeTabId;
			if (isActive) {
				tabs.push(this.props.tabs.contents[tab.tabId]);
			}
		});

		return tabs;
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.activeTab) {
			this.setState({ activeTabId: nextProps.activeTab });
		}
		if (nextProps.tabData) {
			this.setState({ tabData: nextProps.tabData });
		}
	}

	tabChanged(newTab) {
		this.props.dispatch(actions.changeTab(newTab, ""));
	}

	render() {
		let tabButtons = <></>;
		let renderTabs = <></>;
		if (this.props.tabs.tabDefinitions) {
			tabButtons = this.renderButtons(this.props.tabs.tabDefinitions);
			renderTabs = this.renderContents(this.props.tabs.tabDefinitions);
		}

		return (
			<div className="row">
				<div className="col-lg-1 col-md-1 col-sm-1"></div>
				<div className="col-lg-10 col-md-10 col-sm-1 p-t-80 p-b-80">
					<div className="card shadow-lg">
						<div className="p-all-25">
							<div className="pull-up-sm text-center p-b-30">
								<div className="avatar avatar avatar-lg">
									<img src={logo} alt="" />
								</div>
							</div>
							<ul className="nav nav-pills nav-justified">{tabButtons}</ul>
							<div className="tab-content wizardContent">{renderTabs}</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
const mapStateToProps = function(state) {
	return state.wizardReducer;
};

export default connect(mapStateToProps)(Wizard);
