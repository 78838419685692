import React, { Component } from 'react';

export default class UserNotes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: props.userProfile
        };
    }
    render() {
        return (
            <div className="card m-b-30">
                <div className="card-header">

                    <div className="card-title">
                        <div className="avatar mr-2 avatar-xs">
                            <div className="avatar-title bg-warning rounded-circle">
                                <i className="mdi mdi-shape-outline"></i>
                            </div>
                        </div>
                        Notes
                        </div>
                </div>
                <div className="card-body">
                    <textarea className="form-control" placeholder="Add Notes"></textarea>
                    <div className="m-t-10">
                        <button className="btn btn-primary"><i className="mdi mdi-comment"></i> Add Notes</button>
                    </div>
                </div>
            </div>);
    }
}

