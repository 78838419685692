import Types from "../actions/actionTypes";
import uuid from "uuid";

const initialState = {
	notifications: [],
};
const notifcationReducer = (state = initialState, action) => {
	switch (action.type) {
		case Types.NOTIFICATION_CREATED:
			var notification = action.notification;
			notification.id = uuid.v4();

			var notifications = [...state.notifications, notification];
			return { notifications };
		case Types.NOTIFICATION_DELETED:
			console.log("Remove notification" + action.notificationId);
			console.log(state.notifications);
			var notificationId = action.notificationId;
			var notifications = state.notifications.filter(x => x.id !== notificationId);
			
			console.log(state.notifications);
			return { notifications };
		default:
			return state;
	}
};
export default notifcationReducer;
