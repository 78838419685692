import Types from "./actionTypes";

const pinsideBar = sidebarPinned => ({
    type: Types.SIDEBAR_TOGGLE,
});

const showSideBar = sidebarShown => ({
    type: Types.SIDEBAR_SHOWN,
    sidebarShown: sidebarShown
});

const showListModal = (showModal) => ({
    type: Types.SHOW_LIST_MODAL,
    showListModal: showModal
});

const showSearchBar = (showBar) => ({
    type: Types.SEARCHBAR_SHOWN,
    searchBarShown: showBar
});

const addNotificationObject = notification => ({
    type: Types.NOTIFICATION_CREATED,
    notification: notification
});

const addNotification = (title, description) => ({
    type: Types.NOTIFICATION_CREATED,
    notification: { title, description}
});

const addErrorNotification = (title, description) => ({
    type: Types.NOTIFICATION_CREATED,
    notification: { title, description, status:2}
})
const addInfoNotification = (title, description) => ({
    type: Types.NOTIFICATION_CREATED,
    notification: { title, description, status:3}
});
const addSuccessNotification = (title, description) => ({
    type: Types.NOTIFICATION_CREATED,
    notification: { title, description, status:0}
});
const addWarningNotification = (title, description) => ({
    type: Types.NOTIFICATION_CREATED,
    notification: { title, description, status:1}
});


const removeNotification = (id) => ({
    type: Types.NOTIFICATION_DELETED,
    notificationId: id
})

const changeTab = (tabId, wizardId) => ({
    type: Types.WIZARD_TAB_CHANGED,
    tabId: tabId,
    wizardId: wizardId
})

const tabSubmitted = (tabId, tabData) => ({
    type: Types.WIZARD_TAB_SUBMITTED,
    tabId,
    tabData
});

const taskDragStart = (task) => ({
    type: Types.TASK_DRAG_START,
    task
});

const taskDragEnd = () => ({
    type: Types.TASK_DRAG_END
});

const taskDrop = (dropTarget) => ({
    type: Types.TASK_DROPPED,
    dropTarget
})

const taskEdited = (task) => ({
    type: Types.TASK_EDITED,
    task
});

const taskEditClosed = () => ({
    type: Types.TASK_CLOSED
})

export default {
    pinsideBar,
    showSideBar,
    showSearchBar,
    addNotification,
    removeNotification,
    addNotificationObject,
    changeTab,
    tabSubmitted,
    taskDragStart,
    taskDragEnd,
    taskDrop,
    taskEditClosed,
    taskEdited,
    addErrorNotification,
    addSuccessNotification,
    addWarningNotification,
    addInfoNotification
}