import React, { Component } from "react";
import TextInput from "../../components/Inputs/TextInput";
import TextField from "@material-ui/core/TextField";
import PartCell from "../../components/Parts/PartCell";
import SelectionBox from "../../components/Inputs/SelectionBox";
import CustomerRepository from "../../repositories/CustomerRepository";

export default class JobCustomerSelection extends Component {
	constructor(props) {
		super(props);
		this.customerSelected = this.customerSelected.bind(this);
		this.buildingSelected = this.buildingSelected.bind(this);
		this.state = { siteDisabled: true };
		this.validate = this.validate.bind(this);

		if (this.props.tabPane) {
			this.state.tabId = this.props.tabPane.tabId;
		}

		if (this.props.state) {
			this.state = this.props.state;
		}
	}

	customerSelected(e, value) {
		console.log(value);
		this.setState({ siteDisabled: value == undefined, selectedCustomer: value });
		var currentState = { ...this.state, siteDisabled: value == undefined, selectedCustomer: value};
		this.props.onUpdated(currentState);
		this.validate();
	}

	buildingSelected(e, value) {
		console.log(value);
		this.setState({ selectedBuilding: value });
		var currentState = { ...this.state, selectedBuilding: value,  };
		this.props.onUpdated(currentState);
		this.validate();
	}

	validate() {
		if (this.state.selectedCustomer === undefined) {
			this.props.onValid(this.state.tabId, false);
			return;
		}

		this.props.onValid(this.state.tabId, true);
	}

	render() {
		return (
			<div className="jobCustomerSelection align-middle">
				<h3>
					<span className="align-middle">Customer Selection</span>
				</h3>
				<div className="row">
					<div className="col-lg-2 col-md-1"></div>
					<div className="col-lg-8 col-md-10">
						<SelectionBox
							label="Select Customer"
							onSelected={this.customerSelected}
                            searchFunction={CustomerRepository.GetCustomerPage}
							noOptionsText="No Customers"
							value={this.state.selectedCustomer}
							mapping={x => x.name}
						/>
					</div>
				</div>
				<div className="row">
					<div className="col-lg-2 col-md-1"></div>
					<div className="col-lg-8 col-md-10">
						<SelectionBox
							label="Select Site (optional)"
							onSelected={this.buildingSelected}
							noOptionsText="No Sites for this customer"
							disabled={this.state.siteDisabled}
							searchLocation="Part"
							value={this.state.selectedBuilding}
							mapping={x => x.name}
						/>
						<label>Please select a site for this job</label>
					</div>
				</div>
			</div>
		);
	}
}
