import React, { Component } from "react";

export default class HistoryItem extends Component {
	render() {
        let historyItem = this.props.history || {};
		return (
			<div className="list-group-item d-flex  align-items-center">
				<div className="m-r-20">
					<div className="avatar avatar-sm ">
						<div className="avatar-title rounded-circle bg-dark">{historyItem.name}</div>
					</div>
				</div>
				<div className="">
					<div>
						{historyItem.userName} <span className="text-muted"> {historyItem.operation} </span>
					</div>
					<div className="text-muted">an 2 Hrs ago</div>
				</div>
			</div>
		);
	}
}
