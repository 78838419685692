import React, { Component } from "react";
import logo from "../../style/img/logo.svg";

export default class TimelineItem extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: props.data,
		};
		this.renderDate = this.renderDate.bind(this);
	}

	renderDate() {
		return this.state.data.date;
	}

	render() {
		return (
			<div className="timeline-item">
				<div className="timeline-wrapper">
					<div className="">
						<div className="avatar avatar-sm">
							<div className={"avatar-title bg-" + this.state.data.colour + " rounded-circle"}>
								<i className={"mdi " + this.state.data.logo}></i>
							</div>
						</div>
					</div>
					<div className="col-auto">
						<h6 className="m-0">{this.state.data.title}</h6>
					</div>
					<div className="ml-auto col-auto text-muted">{this.renderDate}</div>
				</div>
			</div>
		);
	}
}
