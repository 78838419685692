import React, { Component } from "react";
import TextInput from "../../components/Inputs/TextInput";
import TextField from "@material-ui/core/TextField";
import PartCell from "../../components/Parts/PartCell";
import { DatePicker, TimePicker, DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { convertFromHTML, ContentState, convertToRaw } from 'draft-js'
import MUIRichTextEditor from 'mui-rte'
import { createMuiTheme, Theme, MuiThemeProvider } from '@material-ui/core/styles'
import { stateToHTML } from 'draft-js-export-html';

export default class JobCreationSummary extends Component {
	constructor(props) {
		super(props);
		this.onTitleUpdated = this.onTitleUpdated.bind(this);
		this.validate = this.validate.bind(this);
		this.onDescriptionUpdated = this.onDescriptionUpdated.bind(this);
		this.handleStartDateChange = this.handleStartDateChange.bind(this);

		this.handleEndDateChange = this.handleEndDateChange.bind(this);

		this.state = {
			siteDisabled: true,
			startDate: new Date(),
			endDate: new Date(),
			titleValid: true,
		};
		if (this.props.tabPane) {
			this.state.tabId = this.props.tabPane.tabId;
		}

		if (this.props.state) {
			this.state = this.props.state;
		}
	}
	onTitleUpdated(e) {
		let titleValid = true;
		if (e.target.value === undefined || e.target.value === "") {
			titleValid = false;
		}
		this.setState({ title: e.target.value, titleValid });
		var currentState = { ...this.state, title: e.target.value };
		console.log(e.target.value);
		console.log(currentState);
		this.props.onUpdated(currentState);
		this.validate();
	}

	onDescriptionUpdated(state) {
		let htmlState = stateToHTML(state.getCurrentContent());
		this.setState({ description: htmlState });
		var currentState = { ...this.state, description: htmlState };
		this.props.onUpdated(currentState);
		this.validate();
	}

	handleStartDateChange(date) {
		this.setState({ startDate: date });
		var currentState = { ...this.state, startDate: date };
		this.props.onUpdated(currentState);
		this.validate();
	}

	handleEndDateChange(date) {
		this.setState({ endDate: date });
		var currentState = { ...this.state, endDate: date };
		this.props.onUpdated(currentState);
		this.validate();
	}

	validate() {
		if (this.state.title === "" || this.state.title === undefined) {
			this.props.onValid(this.state.tabId, false);
			return;
		}
		if (this.state.endDate < this.state.startDate) {
			this.props.onValid(this.state.tabId, false);
			return;
		}

		this.props.onValid(this.state.tabId, true);
	}

	render() {
		let titleHelperText = "";
		if (!this.state.titleValid) {
			titleHelperText = "Title must have a value";
		}
		return (
			<div className="jobCreationSummary align-middle">
				<h3>
					<span className="align-middle">Summary</span>
				</h3>
				<div className="row">
					<div className="col-lg-1 col-md-1" />
					<div className="col-lg-7 col-md-10">
						<TextField variant="outlined" label="Title" required error={!this.state.titleValid} helperText={titleHelperText} onChange={this.onTitleUpdated} value={this.state.title} />
					</div>
					<div className="col-lg-4">

						<div className="jobDatePicker">
							<MuiPickersUtilsProvider utils={MomentUtils}>
								<div className="row">
									<div className="col-lg-2 col-md-1"></div>
									<div className="col-lg-8 col-md-10">
										<div className="row">
											<div className="col-3">
												<label>Start Date</label>
											</div>
											<div className="col-9">
												<DatePicker format="dddd, DD MMM YYYY" disablePast value={this.state.startDate} autoOk onChange={this.handleStartDateChange} />
											</div>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-lg-2 col-md-1"></div>
									<div className="col-lg-8 col-md-10">
										<div className="row">
											<div className="col-3">
												<label>End Date</label>
											</div>
											<div className="col-9">
												<DatePicker
													format="dddd, DD MMM YYYY"
													disablePast
													value={this.state.endDate}
													minDate={this.state.startDate}
													minDateMessage={"End date cannot be before the start date"}
													onChange={this.handleEndDateChange}
												/>
											</div>
										</div>
									</div>
								</div>
							</MuiPickersUtilsProvider>
						</div>
					</div>

				</div>
				<hr />
				<div className="row">
					<div className="col-lg-1 col-md-1"></div>
					<div className="col-lg-10 col-md-10">
						<MuiThemeProvider theme={theme}>
							<MUIRichTextEditor
								variant="outlined"
								label="Description"
								controls={["title", "bold", "italic", "underline", "strikethrough", "undo", "redo", "link", "quote", "bulletList", "numberList", "clear"]}
								onChange={this.onDescriptionUpdated} />
						</MuiThemeProvider>
						<div dangerouslySetInnerHTML={{ __html: this.state.description }} />
					</div>
				</div>
			</div>
		);
	}
}
export const theme = createMuiTheme({
	palette: {
		primary: {
			main: "#000000"
		}
	},
	overrides: {
		MUIRichTextEditor: {
			root: {
				border: "1px solid #ebebeb",
			},
			container: {
				display: "flex",
				flexDirection: "column-reverse"
			},
			editor: {
				padding: "20px",
				paddingTop: 0,
				height: "200px",
				maxHeight: "200px",
				overflow: "auto"
			},
			toolbar: {
				borderTop: "1px solid gray",
				backgroundColor: "#ebebeb"
			},
			placeHolder: {
				height: "200px",
				top: 0,
				paddingLeft: 20,
				width: "100%",
				position: "absolute",
			},
			anchorLink: {
				color: "#333333",
				textDecoration: "underline"
			}
		}
	}
})
