import React, { Component } from "react";

import { connect } from "react-redux";
import actions from "../../actions/actions";
import Drawer from "@material-ui/core/Drawer";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import JobSearchResult from "./SearchResultTypes/JobSearchResult";
import JobTaskResult from "./SearchResultTypes/JobTaskResult";
import SearchRepository from "../../repositories/SearchRepository";
import QuoteResult from "./SearchResultTypes/QuoteResult";

class SearchPanel extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showing: props.searchBarShown,
			searchResult: {}
		};

		this.closeState = this.closeState.bind(this);
		this.handleClickAway = this.handleClickAway.bind(this);
		this.onSearchTextEntered = this.onSearchTextEntered.bind(this);
		this.keyCapture = this.keyCapture.bind(this);
		this.submit = this.submit.bind(this);
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.searchBarShown !== undefined) {
			this.setState({ showing: nextProps.searchBarShown });
		}
	}

	closeState() {
		this.props.dispatch(actions.showSearchBar(false));
	}

	handleClickAway() {
		this.props.dispatch(actions.showSearchBar(false));
	}

	submit() {
		var searchText = this.state.searchText;
		SearchRepository.SearchJobs(searchText, 1, 25)
			.then((response) => {
				console.log(response);
				this.setState({ searchResult: response });
			});

	}

	keyCapture(input) {
		if (input.key === "Enter") {
			this.submit();
			console.log("Search Submitted" + this.state.searchText);
		}
	}

	onSearchTextEntered(e) {
		this.setState({ searchText: e.target.value });
	}

	generateTypes(results) {
		if (results == undefined) {
			return <></>;
		}

		let resultsOutput = [];
		results.forEach(element => {
			resultsOutput.push(this.pickType(element));
		});
		return resultsOutput;
	}

	pickType(result) {
		switch (result.objectType) {
			case "Job":
				return (<JobSearchResult job={result} key={result.objectId}></JobSearchResult>)
			case "JobTask":
				return (<JobTaskResult result={result} key={result.objectId}></JobTaskResult>);
			case "Quote":
				return (<QuoteResult result={result} key={result.objectId}></QuoteResult>)
		}
	}


	render() {
		let result = this.state.searchResult;
		let resultHtml = this.generateTypes(result.listData);

		return (
			<Drawer anchor="left" open={this.state.showing}>
				<div className="modal modal-slide-left fade show" id="siteSearchModal" style={{ display: "block" }}>
					<div className="modal-dialog modal-dialog-centered" role="document">
						<div className="modal-content">
							<div className="modal-body p-all-0" id="site-search">
								<button type="button" className="close light" onClick={this.closeState}>
									<span aria-hidden="true">&times;</span>
								</button>
								<div className="form-dark bg-dark text-white p-t-60 p-b-20 bg-dots">
									<h3 className="text-uppercase text-center fw-300 "> Search</h3>
									<div className="container-fluid">
										<div className="col-md-10 p-t-10 m-auto">
											<input type="search" placeholder="Search Something"
												onKeyDown={this.keyCapture}
												value={this.state.searchText}
												onChange={this.onSearchTextEntered}
												className=" search form-control form-control-lg" />
										</div>
									</div>
								</div>
								<div className="">
									<div className="bg-dark text-muted container-fluid p-b-10 text-center text-overline">{result.count} results</div>
									<div className="list-group list">
										{resultHtml}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Drawer >
		);
	}
}

const mapStateToProps = function (state) {
	return state.layoutReducer;
};

export default connect(mapStateToProps)(SearchPanel);
