import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Rating from '@material-ui/lab/Rating';
import logo from "../../style/img/logo.svg";
import DateHelper from "../../helpers/DateHelper";
import { Button, ButtonGroup } from "@material-ui/core";

class JobRow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      job: props.job
    };

    this.goToJobDetails = this.goToJobDetails.bind(this);
  }



  goToJobDetails() {
    this.props.history.push("/job/" + this.state.job.objectId +"/board");
  }

  getProjectType(type){
    if (typeof type != 'number') {
      return type;
    }
    switch (type) {
      case 0:
        return "Quoted";
      case 1:
        return "New";
      case 2:
        return "In-Progress";
      case 3:
        return "Completed";
      case 4:
        return "Staged";
      default:
        break;
    }
  }
  render() {
    let job = this.state.job;
    return (
      <tr className="jobRow">
      <td>{job.name}</td>
        <td> <i className="mdi mdi-18px text-info mdi-circle"></i> {this.getProjectType(job.state)}</td>
        <td>{DateHelper.ShortDateFormatter(job.creationDate)}</td>
        <td>
          <ButtonGroup color="primary"  aria-label="outlined primary button group">
  <Button>Details</Button>
  <Button onClick={this.goToJobDetails}>Tasks</Button>
  <Button>Invoices</Button>
</ButtonGroup>
</td>
      </tr>
    );
  }
}

export default withRouter(JobRow);
