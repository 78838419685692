import React, { Component } from 'react';
import logo from '../../style/img/logo.svg'
import Avatar from '@material-ui/core/Avatar';

export default class HeaderProfile extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <a className="nav-link dropdown-toggle" href="#" role="button" data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false">
                    <div className="avatar avatar-sm avatar-online">
                        <Avatar>JS</Avatar>
                    </div>
                </a>
                <div className="dropdown-menu  dropdown-menu-right">
                    <a className="dropdown-item" href="#">  Add Account </a>
                    <a className="dropdown-item" href="#">  Reset Password</a>
                    <a className="dropdown-item" href="#">  Help </a>
                    <div className="dropdown-divider"></div>
                    <a className="dropdown-item" href="#"> Logout</a>
                </div>
            </div>);
    }
}