import React, { Component } from 'react';
import DateHelper from '../../helpers/DateHelper';

export default class UserSummary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: props.userProfile
        };
    }
    render() {
        return (
            <div className="card m-b-30">
                <div className="card-header">
                    <div className="card-title">
                        <div className="avatar mr-2 avatar-xs">
                            <div className="avatar-title bg-dark rounded-circle">
                                <i className="mdi mdi-microphone mdi-18px"></i>
                            </div>
                        </div>Introduction</div>
                </div>
                <div className="list-group list  list-group-flush">

                    <div className="list-group-item p-all-15 h6 ">
                        <i className="mdi mdi-briefcase"></i> Date of Birth <a href="#" className="text-underline"> {DateHelper.LongDateFormatter(this.state.user.dob)}</a>
                    </div>
                    <div className="list-group-item p-all-15 h6 ">
                        <i className="mdi mdi-school"></i> Studied at <a href="#" className="text-underline">
                            {this.state.user.school}</a>
                    </div>
                    <div className="list-group-item p-all-15 h6 ">
                        <i className="mdi mdi-home"></i> Lives in <a href="https://goo.gl/maps/p2fXqrx8fdD2"
                            target="_blank" className="text-underline">
                            {this.state.user.homeCity}</a>
                    </div>
                </div>
            </div>);
    }
}