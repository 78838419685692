import React, { Component } from "react";
import { DatePicker, MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { TextField } from "@material-ui/core";
import ColourPicker from "../ColourPicker/ColourPicker";
import Task from "../../models/Task";
import TaskRepository from "../../repositories/TaskRepository";
import { withRouter } from "react-router";

class TaskEventCreation extends Component {
	constructor(props) {
		super(props);
		this.state = {
			startDate: new Date(),
			endDate: new Date(),
			colour: "#3ecf8e",
			title: "",
        };
        this.handleStartDateChange = this.handleStartDateChange.bind(this);
        this.handleEndDateChange = this.handleEndDateChange.bind(this);
        this.onTitleChanged = this.onTitleChanged.bind(this);
        this.onColourSelected = this.onColourSelected.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
	}

	onColourSelected(colour) {
		this.setState({ colour: colour });
    }
    
	handleStartDateChange(date) {
		this.setState({ startDate: date });
    }	

    handleEndDateChange(date) {
		this.setState({ endDate: date });
    }
    
    onTitleChanged(e) {
        this.setState({title: e.target.value});
    }

    async onSubmit() {
        var task = new Task(this.state.title, "", "Open", this.state.startDate, this.state.endDate, this.props.match.params.jobId, this.state.colour);
        var result = await TaskRepository.CreateTask(task);
        if(result === false){
            console.error(result);
        }
        
    }
	render() {
		return (
			<div className="card calender-event-creation">
				<div className="card-header">
					<div className="card-title">Create Task</div>
				</div>
				<div className="card-body">
					<div className="m-b-10">
						<TextField size="small" placeholder="Task Title" variant="outlined" onChange={this.onTitleChanged} value={this.state.title}></TextField>
					</div>
					<ColourPicker
						colours={["#3ecf8e", "#b1c2d9", "#f2545b", "#f0b64b", "#68d4f8", "#6772e5", "#9C6440", "#39634D", "#EADFA1", "#DFE3DB"]}
						onSelected={this.onColourSelected}
					></ColourPicker>
					<hr />
					<h6>Times</h6>
					<MuiPickersUtilsProvider utils={MomentUtils}>
						<DateTimePicker format="HH:mm DD/MM/YYYY" disablePast showTodayButton value={this.state.startDate} onChange={this.handleStartDateChange} minDateMessage={"End date cannot be before the start date"} />
						<DateTimePicker format="HH:mm DD/MM/YYYY" disablePast showTodayButton value={this.state.endDate} onChange={this.handleEndDateChange}/>
					</MuiPickersUtilsProvider>
					<hr />
					<div className="m-t-10">
						<button id="add-new-event" type="button" onClick={this.onSubmit} className="btn btn-primary btn-block">
							Create Event
						</button>
					</div>
				</div>
			</div>
		);
	}
}

export default withRouter(TaskEventCreation);