import React, { Component } from "react";
import logo from "../../style/img/logo.svg";
import { withRouter } from "react-router-dom";

class SidebarButton extends Component {
	constructor(props) {
		super(props);
		this.onNavigation = this.onNavigation.bind(this);
	}

	onNavigation() {
		this.props.history.push(this.props.href);
	}

	render() {
		var icon = "mdi-link-variant";

		if (this.props.icon) {
			icon = this.props.icon;
		}

		var active = "";
		if (this.props.isActive === true) {
			active = "active";
		}
		return (
			<li className={"menu-item " + active}>
				<a href="#" className="menu-link" onClick={this.onNavigation}>
					<span className="menu-label">
						<span className="menu-name">{this.props.buttonName}</span>
					</span>
					<span className="menu-icon">
						<span className="icon-badge badge-success badge badge-pill">{this.props.notificationAmount}</span>
						<i className={"icon-placeholder mdi " + icon} />
					</span>
				</a>
				{this.props.children}
			</li>
		);
	}
}

export default withRouter(SidebarButton);
