import React, { Component } from "react";
import color from "@material-ui/core/colors/amber";

export default class ColourItem extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		let activeClass = "";
		if (this.props.active === true) {
			activeClass = "active";
		}
		return (
			<div className="avatar avatar-xs m-r-5 ">
                <div 
                onClick={() => this.props.onClick(this.props.colour)} 
                className={"avatar-title js-event-color  rounded-circle " + activeClass}
                 style={{ backgroundColor: this.props.colour }}></div>
			</div>
		);
	}
}
