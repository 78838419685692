import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Rating from "@material-ui/lab/Rating";
import logo from "../../style/img/logo.svg";
import PartAmount from "../../models/PartAmount";

class PartQuantityRow extends Component {
	constructor(props) {
		super(props);

		this.state = props.part;

		this.goToPartDetail = this.goToPartDetail.bind(this);
		this.priceUpdated = this.priceUpdated.bind(this);
		this.amountUpdated = this.amountUpdated.bind(this);
	}

	async GetThumbnailImage() {}

	goToPartDetail() {
		this.props.history.push("/part/details/" + this.state.part.objectId);
	}

	priceUpdated(e) {
		this.setState({ partPrice: e.target.value });
		this.update(this.state.partAmount, e.target.value);
	}

	amountUpdated(e) {
		this.setState({ partAmount: e.target.value });
		this.update(e.target.value, this.state.partPrice);
	}

	update(amount, price) {
		let part = this.state.part;
		let partAmountDto = new PartAmount(part);
		partAmountDto.partAmount = amount;
		partAmountDto.partPrice = price;
		this.props.updatedRow(partAmountDto);
	}

	render() {
		return (
			<tr className="partRow">
				<td>
					<div className="avatar-m">
						<img src={logo} className="avatar-img " alt="" />
					</div>
				</td>
				<td>{this.state.part.name}</td>
				<td>
					<input type="number" name="field-name" class="form-control" value={this.state.partAmount} onChange={this.amountUpdated} />
				</td>
				<td>
					<div class="input-group">
						<div class="input-group-prepend">
							<span class="input-group-text">£</span>
						</div>
						<input
							type="number"
							name="field-name"
							class="form-control"
							value={this.state.partPrice}
							onChange={this.priceUpdated}
							data-mask="000.000.000.000.000,00"
							data-mask-reverse="true"
							autocomplete="off"
							maxlength="22"
						/>
					</div>
				</td>
			</tr>
		);
	}
}

export default withRouter(PartQuantityRow);
